import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiAlcoTestProcs: build.query<
      GetApiAlcoTestProcsApiResponse,
      GetApiAlcoTestProcsApiArg
    >({
      query: () => ({ url: `/api/AlcoTestProcs` }),
    }),
    postApiAlcoTestProcs: build.mutation<
      PostApiAlcoTestProcsApiResponse,
      PostApiAlcoTestProcsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/AlcoTestProcs`,
        method: "POST",
        body: queryArg.alcoTestProcModel,
      }),
    }),
    getApiAlcoTestProcsById: build.query<
      GetApiAlcoTestProcsByIdApiResponse,
      GetApiAlcoTestProcsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/AlcoTestProcs/${queryArg.id}` }),
    }),
    putApiAlcoTestProcsById: build.mutation<
      PutApiAlcoTestProcsByIdApiResponse,
      PutApiAlcoTestProcsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/AlcoTestProcs/${queryArg.id}`,
        method: "PUT",
        body: queryArg.alcoTestProcModel,
      }),
    }),
    deleteApiAlcoTestProcsById: build.mutation<
      DeleteApiAlcoTestProcsByIdApiResponse,
      DeleteApiAlcoTestProcsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/AlcoTestProcs/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiAlcoTestProcsGetByExamIdByExamId: build.query<
      GetApiAlcoTestProcsGetByExamIdByExamIdApiResponse,
      GetApiAlcoTestProcsGetByExamIdByExamIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/AlcoTestProcs/GetByExamId/${queryArg.examId}`,
      }),
    }),
    postApiAlcoTestProcsStartAlcoProc: build.mutation<
      PostApiAlcoTestProcsStartAlcoProcApiResponse,
      PostApiAlcoTestProcsStartAlcoProcApiArg
    >({
      query: (queryArg) => ({
        url: `/api/AlcoTestProcs/StartAlcoProc`,
        method: "POST",
        body: queryArg.startAlcoProcRequest,
      }),
    }),
    postApiAlcoTestProcsStartAlcoAndTemp: build.mutation<
      PostApiAlcoTestProcsStartAlcoAndTempApiResponse,
      PostApiAlcoTestProcsStartAlcoAndTempApiArg
    >({
      query: (queryArg) => ({
        url: `/api/AlcoTestProcs/StartAlcoAndTemp`,
        method: "POST",
        body: queryArg.startAlcoProcRequest,
      }),
    }),
    getApiAnswers: build.query<GetApiAnswersApiResponse, GetApiAnswersApiArg>({
      query: () => ({ url: `/api/Answers` }),
    }),
    postApiAnswers: build.mutation<
      PostApiAnswersApiResponse,
      PostApiAnswersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Answers`,
        method: "POST",
        body: queryArg.answerModel,
      }),
    }),
    getApiAnswersById: build.query<
      GetApiAnswersByIdApiResponse,
      GetApiAnswersByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Answers/${queryArg.id}` }),
    }),
    putApiAnswersById: build.mutation<
      PutApiAnswersByIdApiResponse,
      PutApiAnswersByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Answers/${queryArg.id}`,
        method: "PUT",
        body: queryArg.answerModel,
      }),
    }),
    deleteApiAnswersById: build.mutation<
      DeleteApiAnswersByIdApiResponse,
      DeleteApiAnswersByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Answers/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    postApiAnswersAddAnswersList: build.mutation<
      PostApiAnswersAddAnswersListApiResponse,
      PostApiAnswersAddAnswersListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Answers/AddAnswersList`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getApiApiKeys: build.query<GetApiApiKeysApiResponse, GetApiApiKeysApiArg>({
      query: () => ({ url: `/api/ApiKeys` }),
    }),
    postApiApiKeys: build.mutation<
      PostApiApiKeysApiResponse,
      PostApiApiKeysApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ApiKeys`,
        method: "POST",
        body: queryArg.createApiKeyModel,
      }),
    }),
    getApiApiKeysGetByTerminalIdByTerminalId: build.query<
      GetApiApiKeysGetByTerminalIdByTerminalIdApiResponse,
      GetApiApiKeysGetByTerminalIdByTerminalIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ApiKeys/GetByTerminalId/${queryArg.terminalId}`,
      }),
    }),
    postApiAuthenticationLogin: build.mutation<
      PostApiAuthenticationLoginApiResponse,
      PostApiAuthenticationLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Authentication/Login`,
        method: "POST",
        body: queryArg.authModel,
      }),
    }),
    postApiAuthenticationRefreshToken: build.mutation<
      PostApiAuthenticationRefreshTokenApiResponse,
      PostApiAuthenticationRefreshTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Authentication/RefreshToken`,
        method: "POST",
        body: queryArg.tokenModel,
      }),
    }),
    getApiBloodPressProcs: build.query<
      GetApiBloodPressProcsApiResponse,
      GetApiBloodPressProcsApiArg
    >({
      query: () => ({ url: `/api/BloodPressProcs` }),
    }),
    postApiBloodPressProcs: build.mutation<
      PostApiBloodPressProcsApiResponse,
      PostApiBloodPressProcsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/BloodPressProcs`,
        method: "POST",
        body: queryArg.bloodPressProcModel,
      }),
    }),
    getApiBloodPressProcsById: build.query<
      GetApiBloodPressProcsByIdApiResponse,
      GetApiBloodPressProcsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/BloodPressProcs/${queryArg.id}` }),
    }),
    putApiBloodPressProcsById: build.mutation<
      PutApiBloodPressProcsByIdApiResponse,
      PutApiBloodPressProcsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/BloodPressProcs/${queryArg.id}`,
        method: "PUT",
        body: queryArg.bloodPressProcModel,
      }),
    }),
    deleteApiBloodPressProcsById: build.mutation<
      DeleteApiBloodPressProcsByIdApiResponse,
      DeleteApiBloodPressProcsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/BloodPressProcs/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiBloodPressProcsGetByExamIdByExamId: build.query<
      GetApiBloodPressProcsGetByExamIdByExamIdApiResponse,
      GetApiBloodPressProcsGetByExamIdByExamIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/BloodPressProcs/GetByExamId/${queryArg.examId}`,
      }),
    }),
    postApiBloodPressProcsStartBloodProc: build.mutation<
      PostApiBloodPressProcsStartBloodProcApiResponse,
      PostApiBloodPressProcsStartBloodProcApiArg
    >({
      query: (queryArg) => ({
        url: `/api/BloodPressProcs/StartBloodProc`,
        method: "POST",
        body: queryArg.startBloodProcRequest,
      }),
    }),
    getApiCertificates: build.query<
      GetApiCertificatesApiResponse,
      GetApiCertificatesApiArg
    >({
      query: () => ({ url: `/api/Certificates` }),
    }),
    postApiCertificates: build.mutation<
      PostApiCertificatesApiResponse,
      PostApiCertificatesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Certificates`,
        method: "POST",
        body: queryArg.certificateModel,
      }),
    }),
    getApiCertificatesById: build.query<
      GetApiCertificatesByIdApiResponse,
      GetApiCertificatesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Certificates/${queryArg.id}` }),
    }),
    putApiCertificatesById: build.mutation<
      PutApiCertificatesByIdApiResponse,
      PutApiCertificatesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Certificates/${queryArg.id}`,
        method: "PUT",
        body: queryArg.certificateModel,
      }),
    }),
    deleteApiCertificatesById: build.mutation<
      DeleteApiCertificatesByIdApiResponse,
      DeleteApiCertificatesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Certificates/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiCertificatesGetAllByPersonIdByPersonId: build.query<
      GetApiCertificatesGetAllByPersonIdByPersonIdApiResponse,
      GetApiCertificatesGetAllByPersonIdByPersonIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Certificates/GetAllByPersonId/${queryArg.personId}`,
      }),
    }),
    getApiConclusions: build.query<
      GetApiConclusionsApiResponse,
      GetApiConclusionsApiArg
    >({
      query: () => ({ url: `/api/Conclusions` }),
    }),
    postApiConclusions: build.mutation<
      PostApiConclusionsApiResponse,
      PostApiConclusionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Conclusions`,
        method: "POST",
        body: queryArg.conclusionModel,
      }),
    }),
    getApiConclusionsById: build.query<
      GetApiConclusionsByIdApiResponse,
      GetApiConclusionsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Conclusions/${queryArg.id}` }),
    }),
    putApiConclusionsById: build.mutation<
      PutApiConclusionsByIdApiResponse,
      PutApiConclusionsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Conclusions/${queryArg.id}`,
        method: "PUT",
        body: queryArg.conclusionModel,
      }),
    }),
    deleteApiConclusionsById: build.mutation<
      DeleteApiConclusionsByIdApiResponse,
      DeleteApiConclusionsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Conclusions/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiConclusionsGetByExamIdByExamId: build.query<
      GetApiConclusionsGetByExamIdByExamIdApiResponse,
      GetApiConclusionsGetByExamIdByExamIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Conclusions/GetByExamId/${queryArg.examId}`,
      }),
    }),
    getApiConclusionsGetAllByPatientIdByPatientId: build.query<
      GetApiConclusionsGetAllByPatientIdByPatientIdApiResponse,
      GetApiConclusionsGetAllByPatientIdByPatientIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Conclusions/GetAllByPatientId/${queryArg.patientId}`,
        params: { lastId: queryArg.lastId, filter: queryArg.filter },
      }),
    }),
    postApiConclusionsGenerateDocumentByConclusionId: build.mutation<
      PostApiConclusionsGenerateDocumentByConclusionIdApiResponse,
      PostApiConclusionsGenerateDocumentByConclusionIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Conclusions/GenerateDocument/${queryArg.conclusionId}`,
        method: "POST",
      }),
    }),
    postApiConclusionsSignByConclusionId: build.mutation<
      PostApiConclusionsSignByConclusionIdApiResponse,
      PostApiConclusionsSignByConclusionIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Conclusions/Sign/${queryArg.conclusionId}`,
        method: "POST",
        body: queryArg.body,
        params: { certificateId: queryArg.certificateId },
      }),
    }),
    getApiDoctors: build.query<GetApiDoctorsApiResponse, GetApiDoctorsApiArg>({
      query: (queryArg) => ({
        url: `/api/Doctors`,
        params: { isActive: queryArg.isActive },
      }),
    }),
    postApiDoctors: build.mutation<
      PostApiDoctorsApiResponse,
      PostApiDoctorsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Doctors`,
        method: "POST",
        body: queryArg.doctorModel,
      }),
    }),
    getApiDoctorsById: build.query<
      GetApiDoctorsByIdApiResponse,
      GetApiDoctorsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Doctors/${queryArg.id}` }),
    }),
    putApiDoctorsById: build.mutation<
      PutApiDoctorsByIdApiResponse,
      PutApiDoctorsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Doctors/${queryArg.id}`,
        method: "PUT",
        body: queryArg.doctorModel,
      }),
    }),
    deleteApiDoctorsById: build.mutation<
      DeleteApiDoctorsByIdApiResponse,
      DeleteApiDoctorsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Doctors/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiDoctorsGetByPersonIdByPersonId: build.query<
      GetApiDoctorsGetByPersonIdByPersonIdApiResponse,
      GetApiDoctorsGetByPersonIdByPersonIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Doctors/GetByPersonId/${queryArg.personId}`,
      }),
    }),
    getApiEducations: build.query<
      GetApiEducationsApiResponse,
      GetApiEducationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Educations`,
        params: { doctorId: queryArg.doctorId },
      }),
    }),
    postApiEducations: build.mutation<
      PostApiEducationsApiResponse,
      PostApiEducationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Educations`,
        method: "POST",
        body: queryArg.educationModel,
      }),
    }),
    getApiEducationsById: build.query<
      GetApiEducationsByIdApiResponse,
      GetApiEducationsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Educations/${queryArg.id}` }),
    }),
    putApiEducationsById: build.mutation<
      PutApiEducationsByIdApiResponse,
      PutApiEducationsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Educations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.educationModel,
      }),
    }),
    deleteApiEducationsById: build.mutation<
      DeleteApiEducationsByIdApiResponse,
      DeleteApiEducationsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Educations/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiExams: build.query<GetApiExamsApiResponse, GetApiExamsApiArg>({
      query: (queryArg) => ({
        url: `/api/Exams`,
        params: {
          page: queryArg.page,
          offset: queryArg.offset,
          searchInfo: queryArg.searchInfo,
          sort: queryArg.sort,
          filter: queryArg.filter,
          isAdmitted: queryArg.isAdmitted,
          isToday: queryArg.isToday,
        },
      }),
    }),
    postApiExams: build.mutation<PostApiExamsApiResponse, PostApiExamsApiArg>({
      query: (queryArg) => ({
        url: `/api/Exams`,
        method: "POST",
        body: queryArg.examModel,
      }),
    }),
    getApiExamsById: build.query<
      GetApiExamsByIdApiResponse,
      GetApiExamsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Exams/${queryArg.id}` }),
    }),
    putApiExamsById: build.mutation<
      PutApiExamsByIdApiResponse,
      PutApiExamsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Exams/${queryArg.id}`,
        method: "PUT",
        body: queryArg.examModel,
      }),
    }),
    deleteApiExamsById: build.mutation<
      DeleteApiExamsByIdApiResponse,
      DeleteApiExamsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Exams/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiExamsGetAllByPatientIdByPatientId: build.query<
      GetApiExamsGetAllByPatientIdByPatientIdApiResponse,
      GetApiExamsGetAllByPatientIdByPatientIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Exams/GetAllByPatientId/${queryArg.patientId}`,
        params: { lastId: queryArg.lastId, filter: queryArg.filter },
      }),
    }),
    getApiExamsCheckStartExamAvailableByPatientId: build.query<
      GetApiExamsCheckStartExamAvailableByPatientIdApiResponse,
      GetApiExamsCheckStartExamAvailableByPatientIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Exams/CheckStartExamAvailable/${queryArg.patientId}`,
      }),
    }),
    postApiExamsStartExam: build.mutation<
      PostApiExamsStartExamApiResponse,
      PostApiExamsStartExamApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Exams/StartExam`,
        method: "POST",
        body: queryArg.startExamRequest,
      }),
    }),
    postApiExamsStopExamById: build.mutation<
      PostApiExamsStopExamByIdApiResponse,
      PostApiExamsStopExamByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Exams/StopExam/${queryArg.id}`,
        method: "POST",
      }),
    }),
    putApiExamsChangeExamStatusById: build.mutation<
      PutApiExamsChangeExamStatusByIdApiResponse,
      PutApiExamsChangeExamStatusByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Exams/ChangeExamStatus/${queryArg.id}`,
        method: "PUT",
        params: { statusType: queryArg.statusType },
      }),
    }),
    getApiFilesDownloadFileByFileName: build.query<
      GetApiFilesDownloadFileByFileNameApiResponse,
      GetApiFilesDownloadFileByFileNameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Files/DownloadFile/${queryArg.fileName}`,
      }),
    }),
    postApiFilesUploadFile: build.mutation<
      PostApiFilesUploadFileApiResponse,
      PostApiFilesUploadFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Files/UploadFile`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getApiFilesStaticDocsByType: build.query<
      GetApiFilesStaticDocsByTypeApiResponse,
      GetApiFilesStaticDocsByTypeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Files/StaticDocs/${queryArg["type"]}`,
      }),
    }),
    postApiFillDbCleanAndFillDb: build.mutation<
      PostApiFillDbCleanAndFillDbApiResponse,
      PostApiFillDbCleanAndFillDbApiArg
    >({
      query: () => ({ url: `/api/FillDb/CleanAndFillDB`, method: "POST" }),
    }),
    postApiFillDbCleanDb: build.mutation<
      PostApiFillDbCleanDbApiResponse,
      PostApiFillDbCleanDbApiArg
    >({
      query: () => ({ url: `/api/FillDb/CleanDB`, method: "POST" }),
    }),
    postApiFillDbCreateProdUser: build.mutation<
      PostApiFillDbCreateProdUserApiResponse,
      PostApiFillDbCreateProdUserApiArg
    >({
      query: () => ({ url: `/api/FillDb/CreateProdUser`, method: "POST" }),
    }),
    getApiIPs: build.query<GetApiIPsApiResponse, GetApiIPsApiArg>({
      query: () => ({ url: `/api/IPs` }),
    }),
    postApiIPs: build.mutation<PostApiIPsApiResponse, PostApiIPsApiArg>({
      query: (queryArg) => ({
        url: `/api/IPs`,
        method: "POST",
        body: queryArg.ipModel,
      }),
    }),
    getApiIPsById: build.query<GetApiIPsByIdApiResponse, GetApiIPsByIdApiArg>({
      query: (queryArg) => ({ url: `/api/IPs/${queryArg.id}` }),
    }),
    putApiIPsById: build.mutation<
      PutApiIPsByIdApiResponse,
      PutApiIPsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/IPs/${queryArg.id}`,
        method: "PUT",
        body: queryArg.ipModel,
      }),
    }),
    deleteApiIPsById: build.mutation<
      DeleteApiIPsByIdApiResponse,
      DeleteApiIPsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/IPs/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiLuxmeter: build.query<
      GetApiLuxmeterApiResponse,
      GetApiLuxmeterApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Luxmeter`,
        params: { terminalId: queryArg.terminalId },
      }),
    }),
    postApiLuxmeter: build.mutation<
      PostApiLuxmeterApiResponse,
      PostApiLuxmeterApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Luxmeter`,
        method: "POST",
        body: queryArg.addLuxmetersRequest,
      }),
    }),
    getApiLuxmeterGetClosestByTerminalId: build.query<
      GetApiLuxmeterGetClosestByTerminalIdApiResponse,
      GetApiLuxmeterGetClosestByTerminalIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Luxmeter/GetClosestByTerminalId`,
        params: {
          dateTime: queryArg.dateTime,
          terminalId: queryArg.terminalId,
        },
      }),
    }),
    getApiMedias: build.query<GetApiMediasApiResponse, GetApiMediasApiArg>({
      query: () => ({ url: `/api/Medias` }),
    }),
    postApiMedias: build.mutation<
      PostApiMediasApiResponse,
      PostApiMediasApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Medias`,
        method: "POST",
        body: queryArg.mediaModel,
      }),
    }),
    getApiMediasById: build.query<
      GetApiMediasByIdApiResponse,
      GetApiMediasByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Medias/${queryArg.id}` }),
    }),
    putApiMediasById: build.mutation<
      PutApiMediasByIdApiResponse,
      PutApiMediasByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Medias/${queryArg.id}`,
        method: "PUT",
        body: queryArg.mediaModel,
      }),
    }),
    deleteApiMediasById: build.mutation<
      DeleteApiMediasByIdApiResponse,
      DeleteApiMediasByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Medias/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiMedOrganizations: build.query<
      GetApiMedOrganizationsApiResponse,
      GetApiMedOrganizationsApiArg
    >({
      query: () => ({ url: `/api/MedOrganizations` }),
    }),
    postApiMedOrganizations: build.mutation<
      PostApiMedOrganizationsApiResponse,
      PostApiMedOrganizationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MedOrganizations`,
        method: "POST",
        body: queryArg.medOrganizationModel,
      }),
    }),
    getApiMedOrganizationsById: build.query<
      GetApiMedOrganizationsByIdApiResponse,
      GetApiMedOrganizationsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/MedOrganizations/${queryArg.id}` }),
    }),
    putApiMedOrganizationsById: build.mutation<
      PutApiMedOrganizationsByIdApiResponse,
      PutApiMedOrganizationsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MedOrganizations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.medOrganizationModel,
      }),
    }),
    deleteApiMedOrganizationsById: build.mutation<
      DeleteApiMedOrganizationsByIdApiResponse,
      DeleteApiMedOrganizationsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MedOrganizations/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiMindTestProcs: build.query<
      GetApiMindTestProcsApiResponse,
      GetApiMindTestProcsApiArg
    >({
      query: () => ({ url: `/api/MindTestProcs` }),
    }),
    postApiMindTestProcs: build.mutation<
      PostApiMindTestProcsApiResponse,
      PostApiMindTestProcsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MindTestProcs`,
        method: "POST",
        body: queryArg.addMindTestProcRequest,
      }),
    }),
    getApiMindTestProcsById: build.query<
      GetApiMindTestProcsByIdApiResponse,
      GetApiMindTestProcsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/MindTestProcs/${queryArg.id}` }),
    }),
    putApiMindTestProcsById: build.mutation<
      PutApiMindTestProcsByIdApiResponse,
      PutApiMindTestProcsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MindTestProcs/${queryArg.id}`,
        method: "PUT",
        body: queryArg.mindTestProcModel,
      }),
    }),
    deleteApiMindTestProcsById: build.mutation<
      DeleteApiMindTestProcsByIdApiResponse,
      DeleteApiMindTestProcsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MindTestProcs/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiMoTypes: build.query<GetApiMoTypesApiResponse, GetApiMoTypesApiArg>({
      query: () => ({ url: `/api/MOTypes` }),
    }),
    postApiMoTypes: build.mutation<
      PostApiMoTypesApiResponse,
      PostApiMoTypesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MOTypes`,
        method: "POST",
        body: queryArg.moTypeModel,
      }),
    }),
    getApiMoTypesById: build.query<
      GetApiMoTypesByIdApiResponse,
      GetApiMoTypesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/MOTypes/${queryArg.id}` }),
    }),
    putApiMoTypesById: build.mutation<
      PutApiMoTypesByIdApiResponse,
      PutApiMoTypesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MOTypes/${queryArg.id}`,
        method: "PUT",
        body: queryArg.moTypeModel,
      }),
    }),
    deleteApiMoTypesById: build.mutation<
      DeleteApiMoTypesByIdApiResponse,
      DeleteApiMoTypesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MOTypes/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiNotifications: build.query<
      GetApiNotificationsApiResponse,
      GetApiNotificationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Notifications`,
        params: { personId: queryArg.personId },
      }),
    }),
    putApiNotificationsReadById: build.mutation<
      PutApiNotificationsReadByIdApiResponse,
      PutApiNotificationsReadByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Notifications/Read/${queryArg.id}`,
        method: "PUT",
      }),
    }),
    putApiNotificationsReadAllByPersonId: build.mutation<
      PutApiNotificationsReadAllByPersonIdApiResponse,
      PutApiNotificationsReadAllByPersonIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Notifications/ReadAll/${queryArg.personId}`,
        method: "PUT",
      }),
    }),
    deleteApiNotificationsById: build.mutation<
      DeleteApiNotificationsByIdApiResponse,
      DeleteApiNotificationsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Notifications/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    deleteApiNotificationsDeleteAllByPersonId: build.mutation<
      DeleteApiNotificationsDeleteAllByPersonIdApiResponse,
      DeleteApiNotificationsDeleteAllByPersonIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Notifications/DeleteAll/${queryArg.personId}`,
        method: "DELETE",
      }),
    }),
    postApiNotificationsSendTestNotification: build.mutation<
      PostApiNotificationsSendTestNotificationApiResponse,
      PostApiNotificationsSendTestNotificationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Notifications/SendTestNotification`,
        method: "POST",
        params: { personId: queryArg.personId },
      }),
    }),
    getApiOrganizations: build.query<
      GetApiOrganizationsApiResponse,
      GetApiOrganizationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Organizations`,
        params: {
          page: queryArg.page,
          offset: queryArg.offset,
          searchInfo: queryArg.searchInfo,
          sort: queryArg.sort,
        },
      }),
    }),
    postApiOrganizations: build.mutation<
      PostApiOrganizationsApiResponse,
      PostApiOrganizationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Organizations`,
        method: "POST",
        body: queryArg.organizationModel,
      }),
    }),
    getApiOrganizationsById: build.query<
      GetApiOrganizationsByIdApiResponse,
      GetApiOrganizationsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Organizations/${queryArg.id}` }),
    }),
    putApiOrganizationsById: build.mutation<
      PutApiOrganizationsByIdApiResponse,
      PutApiOrganizationsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Organizations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.organizationModel,
      }),
    }),
    deleteApiOrganizationsById: build.mutation<
      DeleteApiOrganizationsByIdApiResponse,
      DeleteApiOrganizationsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Organizations/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiOrgConnects: build.query<
      GetApiOrgConnectsApiResponse,
      GetApiOrgConnectsApiArg
    >({
      query: () => ({ url: `/api/OrgConnects` }),
    }),
    postApiOrgConnects: build.mutation<
      PostApiOrgConnectsApiResponse,
      PostApiOrgConnectsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/OrgConnects`,
        method: "POST",
        body: queryArg.orgConnectModel,
      }),
    }),
    deleteApiOrgConnects: build.mutation<
      DeleteApiOrgConnectsApiResponse,
      DeleteApiOrgConnectsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/OrgConnects`,
        method: "DELETE",
        params: {
          organizationId: queryArg.organizationId,
          medOrganizationId: queryArg.medOrganizationId,
        },
      }),
    }),
    getApiOrgConnectsGetByOrganizationIdByOrganizationId: build.query<
      GetApiOrgConnectsGetByOrganizationIdByOrganizationIdApiResponse,
      GetApiOrgConnectsGetByOrganizationIdByOrganizationIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/OrgConnects/GetByOrganizationId/${queryArg.organizationId}`,
      }),
    }),
    getApiPatients: build.query<
      GetApiPatientsApiResponse,
      GetApiPatientsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Patients`,
        params: { isActive: queryArg.isActive },
      }),
    }),
    postApiPatients: build.mutation<
      PostApiPatientsApiResponse,
      PostApiPatientsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Patients`,
        method: "POST",
        body: queryArg.patientModel,
      }),
    }),
    getApiPatientsById: build.query<
      GetApiPatientsByIdApiResponse,
      GetApiPatientsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Patients/${queryArg.id}` }),
    }),
    putApiPatientsById: build.mutation<
      PutApiPatientsByIdApiResponse,
      PutApiPatientsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Patients/${queryArg.id}`,
        method: "PUT",
        body: queryArg.changePatientRequest,
      }),
    }),
    deleteApiPatientsById: build.mutation<
      DeleteApiPatientsByIdApiResponse,
      DeleteApiPatientsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Patients/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiPatientsGetByPersonalNumberByPersonalNumber: build.query<
      GetApiPatientsGetByPersonalNumberByPersonalNumberApiResponse,
      GetApiPatientsGetByPersonalNumberByPersonalNumberApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Patients/GetByPersonalNumber/${queryArg.personalNumber}`,
      }),
    }),
    getApiPatientsGetByPersonIdByPersonId: build.query<
      GetApiPatientsGetByPersonIdByPersonIdApiResponse,
      GetApiPatientsGetByPersonIdByPersonIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Patients/GetByPersonId/${queryArg.personId}`,
      }),
    }),
    putApiPatientsChangePatientActivityById: build.mutation<
      PutApiPatientsChangePatientActivityByIdApiResponse,
      PutApiPatientsChangePatientActivityByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Patients/ChangePatientActivity/${queryArg.id}`,
        method: "PUT",
        params: { isActive: queryArg.isActive },
      }),
    }),
    getApiPersonPhotos: build.query<
      GetApiPersonPhotosApiResponse,
      GetApiPersonPhotosApiArg
    >({
      query: () => ({ url: `/api/PersonPhotos` }),
    }),
    getApiPersonPhotosById: build.query<
      GetApiPersonPhotosByIdApiResponse,
      GetApiPersonPhotosByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/PersonPhotos/${queryArg.id}` }),
    }),
    putApiPersonPhotosById: build.mutation<
      PutApiPersonPhotosByIdApiResponse,
      PutApiPersonPhotosByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PersonPhotos/${queryArg.id}`,
        method: "PUT",
        body: queryArg.changePersonPhotoRequest,
      }),
    }),
    deleteApiPersonPhotosById: build.mutation<
      DeleteApiPersonPhotosByIdApiResponse,
      DeleteApiPersonPhotosByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PersonPhotos/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiPersonPhotosGetByPersonIdByPersonId: build.query<
      GetApiPersonPhotosGetByPersonIdByPersonIdApiResponse,
      GetApiPersonPhotosGetByPersonIdByPersonIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PersonPhotos/GetByPersonId/${queryArg.personId}`,
      }),
    }),
    putApiPersonPhotosApproveRequest: build.mutation<
      PutApiPersonPhotosApproveRequestApiResponse,
      PutApiPersonPhotosApproveRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PersonPhotos/ApproveRequest`,
        method: "PUT",
        params: { personId: queryArg.personId },
      }),
    }),
    putApiPersonPhotosDeclineRequest: build.mutation<
      PutApiPersonPhotosDeclineRequestApiResponse,
      PutApiPersonPhotosDeclineRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PersonPhotos/DeclineRequest`,
        method: "PUT",
        params: { personId: queryArg.personId },
      }),
    }),
    postApiPersonPhotosUpload: build.mutation<
      PostApiPersonPhotosUploadApiResponse,
      PostApiPersonPhotosUploadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PersonPhotos/Upload`,
        method: "POST",
        body: queryArg.body,
        params: { personId: queryArg.personId },
      }),
    }),
    getApiPersons: build.query<GetApiPersonsApiResponse, GetApiPersonsApiArg>({
      query: (queryArg) => ({
        url: `/api/Persons`,
        params: {
          page: queryArg.page,
          offset: queryArg.offset,
          sort: queryArg.sort,
          searchInfo: queryArg.searchInfo,
          roleNames: queryArg.roleNames,
          userStatus: queryArg.userStatus,
        },
      }),
    }),
    postApiPersons: build.mutation<
      PostApiPersonsApiResponse,
      PostApiPersonsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Persons`,
        method: "POST",
        body: queryArg.addPersonRequest,
      }),
    }),
    getApiPersonsById: build.query<
      GetApiPersonsByIdApiResponse,
      GetApiPersonsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Persons/${queryArg.id}` }),
    }),
    putApiPersonsById: build.mutation<
      PutApiPersonsByIdApiResponse,
      PutApiPersonsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Persons/${queryArg.id}`,
        method: "PUT",
        body: queryArg.changePersonRequest,
      }),
    }),
    deleteApiPersonsById: build.mutation<
      DeleteApiPersonsByIdApiResponse,
      DeleteApiPersonsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Persons/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiPersonsCheckPersonalNumberUniqueByPersonalNumber: build.query<
      GetApiPersonsCheckPersonalNumberUniqueByPersonalNumberApiResponse,
      GetApiPersonsCheckPersonalNumberUniqueByPersonalNumberApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Persons/CheckPersonalNumberUnique/${queryArg.personalNumber}`,
      }),
    }),
    putApiPersonsChangeNotificationToken: build.mutation<
      PutApiPersonsChangeNotificationTokenApiResponse,
      PutApiPersonsChangeNotificationTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Persons/ChangeNotificationToken`,
        method: "PUT",
        params: {
          id: queryArg.id,
          notificationToken: queryArg.notificationToken,
        },
      }),
    }),
    getApiPostTripJournals: build.query<
      GetApiPostTripJournalsApiResponse,
      GetApiPostTripJournalsApiArg
    >({
      query: () => ({ url: `/api/PostTripJournals` }),
    }),
    getApiPostTripJournalsById: build.query<
      GetApiPostTripJournalsByIdApiResponse,
      GetApiPostTripJournalsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/PostTripJournals/${queryArg.id}` }),
    }),
    deleteApiPostTripJournalsById: build.mutation<
      DeleteApiPostTripJournalsByIdApiResponse,
      DeleteApiPostTripJournalsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PostTripJournals/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiPostTripJournalsGenerateJournalDocument: build.query<
      GetApiPostTripJournalsGenerateJournalDocumentApiResponse,
      GetApiPostTripJournalsGenerateJournalDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PostTripJournals/GenerateJournalDocument`,
        params: {
          startDate: queryArg.startDate,
          endDate: queryArg.endDate,
          fileExtension: queryArg.fileExtension,
        },
      }),
    }),
    postApiPostTripJournalsSignById: build.mutation<
      PostApiPostTripJournalsSignByIdApiResponse,
      PostApiPostTripJournalsSignByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PostTripJournals/SignById`,
        method: "POST",
        body: queryArg.signJournalByIdModel,
      }),
    }),
    postApiPostTripJournalsSignByExamId: build.mutation<
      PostApiPostTripJournalsSignByExamIdApiResponse,
      PostApiPostTripJournalsSignByExamIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PostTripJournals/SignByExamId`,
        method: "POST",
        body: queryArg.signJournalByExamIdModel,
      }),
    }),
    getApiPostTripJournalsGetHashByExamIdByExamId: build.query<
      GetApiPostTripJournalsGetHashByExamIdByExamIdApiResponse,
      GetApiPostTripJournalsGetHashByExamIdByExamIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PostTripJournals/GetHashByExamId/${queryArg.examId}`,
      }),
    }),
    getApiPostTripJournalsGetHashByIdById: build.query<
      GetApiPostTripJournalsGetHashByIdByIdApiResponse,
      GetApiPostTripJournalsGetHashByIdByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PostTripJournals/GetHashById/${queryArg.id}`,
      }),
    }),
    getApiPreTripJournals: build.query<
      GetApiPreTripJournalsApiResponse,
      GetApiPreTripJournalsApiArg
    >({
      query: () => ({ url: `/api/PreTripJournals` }),
    }),
    getApiPreTripJournalsById: build.query<
      GetApiPreTripJournalsByIdApiResponse,
      GetApiPreTripJournalsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/PreTripJournals/${queryArg.id}` }),
    }),
    deleteApiPreTripJournalsById: build.mutation<
      DeleteApiPreTripJournalsByIdApiResponse,
      DeleteApiPreTripJournalsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PreTripJournals/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiPreTripJournalsGenerateJournalDocument: build.query<
      GetApiPreTripJournalsGenerateJournalDocumentApiResponse,
      GetApiPreTripJournalsGenerateJournalDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PreTripJournals/GenerateJournalDocument`,
        params: {
          startDate: queryArg.startDate,
          endDate: queryArg.endDate,
          fileExtension: queryArg.fileExtension,
        },
      }),
    }),
    postApiPreTripJournalsSignById: build.mutation<
      PostApiPreTripJournalsSignByIdApiResponse,
      PostApiPreTripJournalsSignByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PreTripJournals/SignById`,
        method: "POST",
        body: queryArg.signJournalByIdModel,
      }),
    }),
    postApiPreTripJournalsSignByExamId: build.mutation<
      PostApiPreTripJournalsSignByExamIdApiResponse,
      PostApiPreTripJournalsSignByExamIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PreTripJournals/SignByExamId`,
        method: "POST",
        body: queryArg.signJournalByExamIdModel,
      }),
    }),
    getApiPreTripJournalsGetHashByExamIdByExamId: build.query<
      GetApiPreTripJournalsGetHashByExamIdByExamIdApiResponse,
      GetApiPreTripJournalsGetHashByExamIdByExamIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PreTripJournals/GetHashByExamId/${queryArg.examId}`,
      }),
    }),
    getApiPreTripJournalsGetHashByIdById: build.query<
      GetApiPreTripJournalsGetHashByIdByIdApiResponse,
      GetApiPreTripJournalsGetHashByIdByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PreTripJournals/GetHashById/${queryArg.id}`,
      }),
    }),
    getApiProcedureDevices: build.query<
      GetApiProcedureDevicesApiResponse,
      GetApiProcedureDevicesApiArg
    >({
      query: () => ({ url: `/api/ProcedureDevices` }),
    }),
    postApiProcedureDevices: build.mutation<
      PostApiProcedureDevicesApiResponse,
      PostApiProcedureDevicesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ProcedureDevices`,
        method: "POST",
        body: queryArg.addProcedureDeviceRequest,
      }),
    }),
    getApiProcedureDevicesById: build.query<
      GetApiProcedureDevicesByIdApiResponse,
      GetApiProcedureDevicesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/ProcedureDevices/${queryArg.id}` }),
    }),
    putApiProcedureDevicesById: build.mutation<
      PutApiProcedureDevicesByIdApiResponse,
      PutApiProcedureDevicesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ProcedureDevices/${queryArg.id}`,
        method: "PUT",
        body: queryArg.procedureDeviceDto,
      }),
    }),
    deleteApiProcedureDevicesById: build.mutation<
      DeleteApiProcedureDevicesByIdApiResponse,
      DeleteApiProcedureDevicesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ProcedureDevices/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiProcedureDevicesGetByNameByName: build.query<
      GetApiProcedureDevicesGetByNameByNameApiResponse,
      GetApiProcedureDevicesGetByNameByNameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ProcedureDevices/GetByName/${queryArg.name}`,
      }),
    }),
    getApiQuestions: build.query<
      GetApiQuestionsApiResponse,
      GetApiQuestionsApiArg
    >({
      query: () => ({ url: `/api/Questions` }),
    }),
    postApiQuestions: build.mutation<
      PostApiQuestionsApiResponse,
      PostApiQuestionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Questions`,
        method: "POST",
        body: queryArg.questionModel,
      }),
    }),
    getApiQuestionsById: build.query<
      GetApiQuestionsByIdApiResponse,
      GetApiQuestionsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Questions/${queryArg.id}` }),
    }),
    putApiQuestionsById: build.mutation<
      PutApiQuestionsByIdApiResponse,
      PutApiQuestionsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Questions/${queryArg.id}`,
        method: "PUT",
        body: queryArg.questionModel,
      }),
    }),
    deleteApiQuestionsById: build.mutation<
      DeleteApiQuestionsByIdApiResponse,
      DeleteApiQuestionsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Questions/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiQuizes: build.query<GetApiQuizesApiResponse, GetApiQuizesApiArg>({
      query: () => ({ url: `/api/Quizes` }),
    }),
    postApiQuizes: build.mutation<
      PostApiQuizesApiResponse,
      PostApiQuizesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Quizes`,
        method: "POST",
        body: queryArg.addQuizRequest,
      }),
    }),
    getApiQuizesById: build.query<
      GetApiQuizesByIdApiResponse,
      GetApiQuizesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Quizes/${queryArg.id}` }),
    }),
    putApiQuizesById: build.mutation<
      PutApiQuizesByIdApiResponse,
      PutApiQuizesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Quizes/${queryArg.id}`,
        method: "PUT",
        body: queryArg.quizModel,
      }),
    }),
    deleteApiQuizesById: build.mutation<
      DeleteApiQuizesByIdApiResponse,
      DeleteApiQuizesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Quizes/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiReasons: build.query<GetApiReasonsApiResponse, GetApiReasonsApiArg>({
      query: () => ({ url: `/api/Reasons` }),
    }),
    postApiReasons: build.mutation<
      PostApiReasonsApiResponse,
      PostApiReasonsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Reasons`,
        method: "POST",
        body: queryArg.reasonModel,
      }),
    }),
    getApiReasonsById: build.query<
      GetApiReasonsByIdApiResponse,
      GetApiReasonsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Reasons/${queryArg.id}` }),
    }),
    putApiReasonsById: build.mutation<
      PutApiReasonsByIdApiResponse,
      PutApiReasonsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Reasons/${queryArg.id}`,
        method: "PUT",
        body: queryArg.reasonModel,
      }),
    }),
    deleteApiReasonsById: build.mutation<
      DeleteApiReasonsByIdApiResponse,
      DeleteApiReasonsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Reasons/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiReferenceIndicators: build.query<
      GetApiReferenceIndicatorsApiResponse,
      GetApiReferenceIndicatorsApiArg
    >({
      query: () => ({ url: `/api/ReferenceIndicators` }),
    }),
    postApiReferenceIndicators: build.mutation<
      PostApiReferenceIndicatorsApiResponse,
      PostApiReferenceIndicatorsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ReferenceIndicators`,
        method: "POST",
        body: queryArg.addReferenceIndicatorRequest,
      }),
    }),
    getApiReferenceIndicatorsById: build.query<
      GetApiReferenceIndicatorsByIdApiResponse,
      GetApiReferenceIndicatorsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/ReferenceIndicators/${queryArg.id}` }),
    }),
    putApiReferenceIndicatorsById: build.mutation<
      PutApiReferenceIndicatorsByIdApiResponse,
      PutApiReferenceIndicatorsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ReferenceIndicators/${queryArg.id}`,
        method: "PUT",
        body: queryArg.referenceIndicatorDto,
      }),
    }),
    deleteApiReferenceIndicatorsById: build.mutation<
      DeleteApiReferenceIndicatorsByIdApiResponse,
      DeleteApiReferenceIndicatorsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ReferenceIndicators/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiReferenceIndicatorsGetByPatientIdByPatientId: build.query<
      GetApiReferenceIndicatorsGetByPatientIdByPatientIdApiResponse,
      GetApiReferenceIndicatorsGetByPatientIdByPatientIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ReferenceIndicators/GetByPatientId/${queryArg.patientId}`,
      }),
    }),
    getApiReportsGeneratePivotTable: build.query<
      GetApiReportsGeneratePivotTableApiResponse,
      GetApiReportsGeneratePivotTableApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Reports/GeneratePivotTable`,
        params: { startDate: queryArg.startDate, endDate: queryArg.endDate },
      }),
    }),
    getApiReportsGenerateExamReport: build.query<
      GetApiReportsGenerateExamReportApiResponse,
      GetApiReportsGenerateExamReportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Reports/GenerateExamReport`,
        params: { startDate: queryArg.startDate, endDate: queryArg.endDate },
      }),
    }),
    getApiRoles: build.query<GetApiRolesApiResponse, GetApiRolesApiArg>({
      query: () => ({ url: `/api/Roles` }),
    }),
    postApiRoles: build.mutation<PostApiRolesApiResponse, PostApiRolesApiArg>({
      query: (queryArg) => ({
        url: `/api/Roles`,
        method: "POST",
        body: queryArg.roleModel,
      }),
    }),
    getApiRolesById: build.query<
      GetApiRolesByIdApiResponse,
      GetApiRolesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Roles/${queryArg.id}` }),
    }),
    putApiRolesById: build.mutation<
      PutApiRolesByIdApiResponse,
      PutApiRolesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Roles/${queryArg.id}`,
        method: "PUT",
        body: queryArg.roleModel,
      }),
    }),
    deleteApiRolesById: build.mutation<
      DeleteApiRolesByIdApiResponse,
      DeleteApiRolesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Roles/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiRolesGetByNameByName: build.query<
      GetApiRolesGetByNameByNameApiResponse,
      GetApiRolesGetByNameByNameApiArg
    >({
      query: (queryArg) => ({ url: `/api/Roles/GetByName/${queryArg.name}` }),
    }),
    getApiSchedules: build.query<
      GetApiSchedulesApiResponse,
      GetApiSchedulesApiArg
    >({
      query: () => ({ url: `/api/Schedules` }),
    }),
    postApiSchedules: build.mutation<
      PostApiSchedulesApiResponse,
      PostApiSchedulesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Schedules`,
        method: "POST",
        body: queryArg.scheduleModel,
      }),
    }),
    getApiSchedulesById: build.query<
      GetApiSchedulesByIdApiResponse,
      GetApiSchedulesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Schedules/${queryArg.id}` }),
    }),
    putApiSchedulesById: build.mutation<
      PutApiSchedulesByIdApiResponse,
      PutApiSchedulesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Schedules/${queryArg.id}`,
        method: "PUT",
        body: queryArg.scheduleModel,
      }),
    }),
    deleteApiSchedulesById: build.mutation<
      DeleteApiSchedulesByIdApiResponse,
      DeleteApiSchedulesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Schedules/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiSpecialities: build.query<
      GetApiSpecialitiesApiResponse,
      GetApiSpecialitiesApiArg
    >({
      query: () => ({ url: `/api/Specialities` }),
    }),
    getApiSpecialitiesById: build.query<
      GetApiSpecialitiesByIdApiResponse,
      GetApiSpecialitiesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Specialities/${queryArg.id}` }),
    }),
    getApiSpeechTestProcs: build.query<
      GetApiSpeechTestProcsApiResponse,
      GetApiSpeechTestProcsApiArg
    >({
      query: () => ({ url: `/api/SpeechTestProcs` }),
    }),
    postApiSpeechTestProcs: build.mutation<
      PostApiSpeechTestProcsApiResponse,
      PostApiSpeechTestProcsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/SpeechTestProcs`,
        method: "POST",
        body: queryArg.addSpeechTestProcRequest,
      }),
    }),
    getApiSpeechTestProcsById: build.query<
      GetApiSpeechTestProcsByIdApiResponse,
      GetApiSpeechTestProcsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/SpeechTestProcs/${queryArg.id}` }),
    }),
    putApiSpeechTestProcsById: build.mutation<
      PutApiSpeechTestProcsByIdApiResponse,
      PutApiSpeechTestProcsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/SpeechTestProcs/${queryArg.id}`,
        method: "PUT",
        body: queryArg.speechTestProcModel,
      }),
    }),
    deleteApiSpeechTestProcsById: build.mutation<
      DeleteApiSpeechTestProcsByIdApiResponse,
      DeleteApiSpeechTestProcsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/SpeechTestProcs/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiTemperatureProcs: build.query<
      GetApiTemperatureProcsApiResponse,
      GetApiTemperatureProcsApiArg
    >({
      query: () => ({ url: `/api/TemperatureProcs` }),
    }),
    postApiTemperatureProcs: build.mutation<
      PostApiTemperatureProcsApiResponse,
      PostApiTemperatureProcsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/TemperatureProcs`,
        method: "POST",
        body: queryArg.temperatureProcModel,
      }),
    }),
    getApiTemperatureProcsById: build.query<
      GetApiTemperatureProcsByIdApiResponse,
      GetApiTemperatureProcsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/TemperatureProcs/${queryArg.id}` }),
    }),
    putApiTemperatureProcsById: build.mutation<
      PutApiTemperatureProcsByIdApiResponse,
      PutApiTemperatureProcsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/TemperatureProcs/${queryArg.id}`,
        method: "PUT",
        body: queryArg.temperatureProcModel,
      }),
    }),
    deleteApiTemperatureProcsById: build.mutation<
      DeleteApiTemperatureProcsByIdApiResponse,
      DeleteApiTemperatureProcsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/TemperatureProcs/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiTemperatureProcsGetByExamIdAsyncByExamId: build.query<
      GetApiTemperatureProcsGetByExamIdAsyncByExamIdApiResponse,
      GetApiTemperatureProcsGetByExamIdAsyncByExamIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/TemperatureProcs/GetByExamIdAsync/${queryArg.examId}`,
      }),
    }),
    postApiTemperatureProcsStartTempProc: build.mutation<
      PostApiTemperatureProcsStartTempProcApiResponse,
      PostApiTemperatureProcsStartTempProcApiArg
    >({
      query: (queryArg) => ({
        url: `/api/TemperatureProcs/StartTempProc`,
        method: "POST",
        body: queryArg.startTempProcRequest,
      }),
    }),
    postApiTemperatureProcsStartAlcoAndTemp: build.mutation<
      PostApiTemperatureProcsStartAlcoAndTempApiResponse,
      PostApiTemperatureProcsStartAlcoAndTempApiArg
    >({
      query: (queryArg) => ({
        url: `/api/TemperatureProcs/StartAlcoAndTemp`,
        method: "POST",
        body: queryArg.startTempProcRequest,
      }),
    }),
    getApiTerminals: build.query<
      GetApiTerminalsApiResponse,
      GetApiTerminalsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Terminals`,
        params: {
          page: queryArg.page,
          offset: queryArg.offset,
          sort: queryArg.sort,
          searchInfo: queryArg.searchInfo,
          status: queryArg.status,
        },
      }),
    }),
    postApiTerminals: build.mutation<
      PostApiTerminalsApiResponse,
      PostApiTerminalsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Terminals`,
        method: "POST",
        body: queryArg.addTerminalRequest,
      }),
    }),
    getApiTerminalsById: build.query<
      GetApiTerminalsByIdApiResponse,
      GetApiTerminalsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Terminals/${queryArg.id}` }),
    }),
    putApiTerminalsById: build.mutation<
      PutApiTerminalsByIdApiResponse,
      PutApiTerminalsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Terminals/${queryArg.id}`,
        method: "PUT",
        body: queryArg.changeTerminalRequest,
      }),
    }),
    deleteApiTerminalsById: build.mutation<
      DeleteApiTerminalsByIdApiResponse,
      DeleteApiTerminalsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Terminals/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiTerminalsGetBySerialNumberBySerialNumber: build.query<
      GetApiTerminalsGetBySerialNumberBySerialNumberApiResponse,
      GetApiTerminalsGetBySerialNumberBySerialNumberApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Terminals/GetBySerialNumber/${queryArg.serialNumber}`,
      }),
    }),
    postApiTerminalsByIdReboot: build.mutation<
      PostApiTerminalsByIdRebootApiResponse,
      PostApiTerminalsByIdRebootApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Terminals/${queryArg.id}/Reboot`,
        method: "POST",
      }),
    }),
    putApiTerminalsChangeTerminalStatus: build.mutation<
      PutApiTerminalsChangeTerminalStatusApiResponse,
      PutApiTerminalsChangeTerminalStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Terminals/ChangeTerminalStatus`,
        method: "PUT",
        params: { id: queryArg.id, status: queryArg.status },
      }),
    }),
    putApiTerminalsByIdChangeDevices: build.mutation<
      PutApiTerminalsByIdChangeDevicesApiResponse,
      PutApiTerminalsByIdChangeDevicesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Terminals/${queryArg.id}/ChangeDevices`,
        method: "PUT",
        body: queryArg.changeTerminalDevicesRequest,
      }),
    }),
    getApiTermoGigrs: build.query<
      GetApiTermoGigrsApiResponse,
      GetApiTermoGigrsApiArg
    >({
      query: () => ({ url: `/api/TermoGigrs` }),
    }),
    postApiTermoGigrs: build.mutation<
      PostApiTermoGigrsApiResponse,
      PostApiTermoGigrsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/TermoGigrs`,
        method: "POST",
        body: queryArg.termoGigrModel,
      }),
    }),
    getApiTermoGigrsGetAllByTerminalIdTerminalId: build.query<
      GetApiTermoGigrsGetAllByTerminalIdTerminalIdApiResponse,
      GetApiTermoGigrsGetAllByTerminalIdTerminalIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/TermoGigrs/GetAllByTerminalId/terminalId`,
        params: { terminalId: queryArg.terminalId },
      }),
    }),
    getApiTermoGigrsGetClosestByTerminalId: build.query<
      GetApiTermoGigrsGetClosestByTerminalIdApiResponse,
      GetApiTermoGigrsGetClosestByTerminalIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/TermoGigrs/GetClosestByTerminalId`,
        params: {
          dateTime: queryArg.dateTime,
          terminalId: queryArg.terminalId,
        },
      }),
    }),
    getApiUserRoles: build.query<
      GetApiUserRolesApiResponse,
      GetApiUserRolesApiArg
    >({
      query: () => ({ url: `/api/UserRoles` }),
    }),
    postApiUserRoles: build.mutation<
      PostApiUserRolesApiResponse,
      PostApiUserRolesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/UserRoles`,
        method: "POST",
        body: queryArg.addUserRoleRequest,
      }),
    }),
    getApiUserRolesGetAllByUserIdByUserId: build.query<
      GetApiUserRolesGetAllByUserIdByUserIdApiResponse,
      GetApiUserRolesGetAllByUserIdByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/UserRoles/GetAllByUserId/${queryArg.userId}`,
      }),
    }),
    getApiUserRolesGetAllByRoleIdByRoleId: build.query<
      GetApiUserRolesGetAllByRoleIdByRoleIdApiResponse,
      GetApiUserRolesGetAllByRoleIdByRoleIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/UserRoles/GetAllByRoleId/${queryArg.roleId}`,
      }),
    }),
    getApiUserRolesUserIdRoleId: build.query<
      GetApiUserRolesUserIdRoleIdApiResponse,
      GetApiUserRolesUserIdRoleIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/UserRoles/userId, roleId`,
        params: { userId: queryArg.userId, roleId: queryArg.roleId },
      }),
    }),
    deleteApiUserRolesUserIdRoleId: build.mutation<
      DeleteApiUserRolesUserIdRoleIdApiResponse,
      DeleteApiUserRolesUserIdRoleIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/UserRoles/userId, roleId`,
        method: "DELETE",
        params: { userId: queryArg.userId, roleId: queryArg.roleId },
      }),
    }),
    getApiUsers: build.query<GetApiUsersApiResponse, GetApiUsersApiArg>({
      query: (queryArg) => ({
        url: `/api/Users`,
        params: {
          page: queryArg.page,
          offset: queryArg.offset,
          sort: queryArg.sort,
          searchInfo: queryArg.searchInfo,
          roleNames: queryArg.roleNames,
        },
      }),
    }),
    postApiUsers: build.mutation<PostApiUsersApiResponse, PostApiUsersApiArg>({
      query: (queryArg) => ({
        url: `/api/Users`,
        method: "POST",
        body: queryArg.addUserRequest,
      }),
    }),
    getApiUsersById: build.query<
      GetApiUsersByIdApiResponse,
      GetApiUsersByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Users/${queryArg.id}` }),
    }),
    putApiUsersById: build.mutation<
      PutApiUsersByIdApiResponse,
      PutApiUsersByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Users/${queryArg.id}`,
        method: "PUT",
        body: queryArg.changeUserRequest,
      }),
    }),
    deleteApiUsersById: build.mutation<
      DeleteApiUsersByIdApiResponse,
      DeleteApiUsersByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Users/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiUsersCheckUserNameUniqueByUserName: build.query<
      GetApiUsersCheckUserNameUniqueByUserNameApiResponse,
      GetApiUsersCheckUserNameUniqueByUserNameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Users/CheckUserNameUnique/${queryArg.userName}`,
      }),
    }),
    putApiUsersChangeUserStatus: build.mutation<
      PutApiUsersChangeUserStatusApiResponse,
      PutApiUsersChangeUserStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Users/ChangeUserStatus`,
        method: "PUT",
        params: { userId: queryArg.userId, status: queryArg.status },
      }),
    }),
    putApiUsersChangePassword: build.mutation<
      PutApiUsersChangePasswordApiResponse,
      PutApiUsersChangePasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Users/ChangePassword`,
        method: "PUT",
        body: queryArg.changePasswordRequest,
      }),
    }),
    putApiUsersResetPassword: build.mutation<
      PutApiUsersResetPasswordApiResponse,
      PutApiUsersResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Users/ResetPassword`,
        method: "PUT",
        body: queryArg.resetPasswordRequest,
      }),
    }),
    getApiWorkPlaces: build.query<
      GetApiWorkPlacesApiResponse,
      GetApiWorkPlacesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/WorkPlaces`,
        params: { doctorId: queryArg.doctorId },
      }),
    }),
    postApiWorkPlaces: build.mutation<
      PostApiWorkPlacesApiResponse,
      PostApiWorkPlacesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/WorkPlaces`,
        method: "POST",
        body: queryArg.workPlaceModel,
      }),
    }),
    getApiWorkPlacesById: build.query<
      GetApiWorkPlacesByIdApiResponse,
      GetApiWorkPlacesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/WorkPlaces/${queryArg.id}` }),
    }),
    putApiWorkPlacesById: build.mutation<
      PutApiWorkPlacesByIdApiResponse,
      PutApiWorkPlacesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/WorkPlaces/${queryArg.id}`,
        method: "PUT",
        body: queryArg.workPlaceModel,
      }),
    }),
    deleteApiWorkPlacesById: build.mutation<
      DeleteApiWorkPlacesByIdApiResponse,
      DeleteApiWorkPlacesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/WorkPlaces/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as apiRTK };
export type GetApiAlcoTestProcsApiResponse =
  /** status 200 OK */ AlcoTestProcModel[];
export type GetApiAlcoTestProcsApiArg = void;
export type PostApiAlcoTestProcsApiResponse = unknown;
export type PostApiAlcoTestProcsApiArg = {
  /** Модель процедуры */
  alcoTestProcModel: AlcoTestProcModel;
};
export type GetApiAlcoTestProcsByIdApiResponse =
  /** status 200 OK */ AlcoTestProcModel;
export type GetApiAlcoTestProcsByIdApiArg = {
  /** Идентификатор процедуры */
  id: number;
};
export type PutApiAlcoTestProcsByIdApiResponse = unknown;
export type PutApiAlcoTestProcsByIdApiArg = {
  /** Идентификатор процедуры */
  id: number;
  /** Модель процедуры */
  alcoTestProcModel: AlcoTestProcModel;
};
export type DeleteApiAlcoTestProcsByIdApiResponse = unknown;
export type DeleteApiAlcoTestProcsByIdApiArg = {
  /** Идентификатор процедуры */
  id: number;
};
export type GetApiAlcoTestProcsGetByExamIdByExamIdApiResponse =
  /** status 200 OK */ AlcoTestProcModel[];
export type GetApiAlcoTestProcsGetByExamIdByExamIdApiArg = {
  /** Идентификатор осмотра */
  examId: number;
};
export type PostApiAlcoTestProcsStartAlcoProcApiResponse =
  /** status 200 OK */ IdResponseDto;
export type PostApiAlcoTestProcsStartAlcoProcApiArg = {
  /** Модель запуска процедуры */
  startAlcoProcRequest: StartAlcoProcRequest;
};
export type PostApiAlcoTestProcsStartAlcoAndTempApiResponse =
  /** status 200 OK */ AlcoAndTermoModel;
export type PostApiAlcoTestProcsStartAlcoAndTempApiArg = {
  /** Модель запуска процедуры */
  startAlcoProcRequest: StartAlcoProcRequest;
};
export type GetApiAnswersApiResponse = /** status 200 OK */ AnswerModel[];
export type GetApiAnswersApiArg = void;
export type PostApiAnswersApiResponse = unknown;
export type PostApiAnswersApiArg = {
  /** Модель ответа на вопрос */
  answerModel: AnswerModel;
};
export type GetApiAnswersByIdApiResponse = /** status 200 OK */ AnswerModel;
export type GetApiAnswersByIdApiArg = {
  /** Идентификатор ответа на вопрос */
  id: number;
};
export type PutApiAnswersByIdApiResponse = unknown;
export type PutApiAnswersByIdApiArg = {
  /** Идентификатор ответа на вопрос */
  id: number;
  /** Модель ответа на вопрос */
  answerModel: AnswerModel;
};
export type DeleteApiAnswersByIdApiResponse = unknown;
export type DeleteApiAnswersByIdApiArg = {
  /** Идентификатор ответа на вопрос */
  id: number;
};
export type PostApiAnswersAddAnswersListApiResponse = unknown;
export type PostApiAnswersAddAnswersListApiArg = {
  body: AnswerModel[];
};
export type GetApiApiKeysApiResponse = /** status 200 OK */ ApiKeyDto[];
export type GetApiApiKeysApiArg = void;
export type PostApiApiKeysApiResponse = unknown;
export type PostApiApiKeysApiArg = {
  /** Модель апи ключа */
  createApiKeyModel: CreateApiKeyModel;
};
export type GetApiApiKeysGetByTerminalIdByTerminalIdApiResponse =
  /** status 200 OK */ ApiKeyDto;
export type GetApiApiKeysGetByTerminalIdByTerminalIdApiArg = {
  /** Идентификатор терминала */
  terminalId: number;
};
export type PostApiAuthenticationLoginApiResponse =
  /** status 200 OK */ TokenModel;
export type PostApiAuthenticationLoginApiArg = {
  authModel: AuthModel;
};
export type PostApiAuthenticationRefreshTokenApiResponse =
  /** status 200 OK */ AccessTokenDto;
export type PostApiAuthenticationRefreshTokenApiArg = {
  /** Access токен для обновления */
  tokenModel: TokenModel;
};
export type GetApiBloodPressProcsApiResponse =
  /** status 200 OK */ BloodPressProcModel[];
export type GetApiBloodPressProcsApiArg = void;
export type PostApiBloodPressProcsApiResponse =
  /** status 200 OK */ IdResponseDto;
export type PostApiBloodPressProcsApiArg = {
  /** Модель процедуры тонометра */
  bloodPressProcModel: BloodPressProcModel;
};
export type GetApiBloodPressProcsByIdApiResponse =
  /** status 200 OK */ BloodPressProcModel;
export type GetApiBloodPressProcsByIdApiArg = {
  /** Идентификатор процедуры */
  id: number;
};
export type PutApiBloodPressProcsByIdApiResponse = unknown;
export type PutApiBloodPressProcsByIdApiArg = {
  /** Идентификатор тонометра */
  id: number;
  bloodPressProcModel: BloodPressProcModel;
};
export type DeleteApiBloodPressProcsByIdApiResponse = unknown;
export type DeleteApiBloodPressProcsByIdApiArg = {
  /** Идентификатор процедуры */
  id: number;
};
export type GetApiBloodPressProcsGetByExamIdByExamIdApiResponse =
  /** status 200 OK */ BloodPressProcModel[];
export type GetApiBloodPressProcsGetByExamIdByExamIdApiArg = {
  /** Идентификатор осмотра */
  examId: number;
};
export type PostApiBloodPressProcsStartBloodProcApiResponse =
  /** status 200 OK */ IdResponseDto;
export type PostApiBloodPressProcsStartBloodProcApiArg = {
  /** Модель запуска процедуры тонометра */
  startBloodProcRequest: StartBloodProcRequest;
};
export type GetApiCertificatesApiResponse =
  /** status 200 OK */ CertificateModel[];
export type GetApiCertificatesApiArg = void;
export type PostApiCertificatesApiResponse = unknown;
export type PostApiCertificatesApiArg = {
  /** Модель сертификата */
  certificateModel: CertificateModel;
};
export type GetApiCertificatesByIdApiResponse =
  /** status 200 OK */ CertificateModel;
export type GetApiCertificatesByIdApiArg = {
  /** Идентификатор сертификата */
  id: number;
};
export type PutApiCertificatesByIdApiResponse = unknown;
export type PutApiCertificatesByIdApiArg = {
  /** Идентификатор сертификата */
  id: number;
  /** Модель сертификата */
  certificateModel: CertificateModel;
};
export type DeleteApiCertificatesByIdApiResponse = unknown;
export type DeleteApiCertificatesByIdApiArg = {
  /** Идентифкатор сертификата */
  id: number;
};
export type GetApiCertificatesGetAllByPersonIdByPersonIdApiResponse =
  /** status 200 OK */ CertificateModel[];
export type GetApiCertificatesGetAllByPersonIdByPersonIdApiArg = {
  /** Идентификатор физ. лица */
  personId: number;
};
export type GetApiConclusionsApiResponse =
  /** status 200 OK */ ConclusionModel[];
export type GetApiConclusionsApiArg = void;
export type PostApiConclusionsApiResponse = unknown;
export type PostApiConclusionsApiArg = {
  /** Модель заключения */
  conclusionModel: ConclusionModel;
};
export type GetApiConclusionsByIdApiResponse =
  /** status 200 OK */ ConclusionResponseDto;
export type GetApiConclusionsByIdApiArg = {
  /** Идентификатор заключения */
  id: number;
};
export type PutApiConclusionsByIdApiResponse = unknown;
export type PutApiConclusionsByIdApiArg = {
  /** Идентификатор заключения */
  id: number;
  /** Модел заключения */
  conclusionModel: ConclusionModel;
};
export type DeleteApiConclusionsByIdApiResponse = unknown;
export type DeleteApiConclusionsByIdApiArg = {
  /** Идентификатор заключения */
  id: number;
};
export type GetApiConclusionsGetByExamIdByExamIdApiResponse =
  /** status 200 OK */ ConclusionResponseDto;
export type GetApiConclusionsGetByExamIdByExamIdApiArg = {
  /** Идентификатор осмотра */
  examId: number;
};
export type GetApiConclusionsGetAllByPatientIdByPatientIdApiResponse =
  /** status 200 OK */ ConclusionResponseDto[];
export type GetApiConclusionsGetAllByPatientIdByPatientIdApiArg = {
  /** Идентификатор пациента */
  patientId: number;
  /** Идентификатор крайнего заключения */
  lastId?: number;
  /** Строка для фильтрации (open, closed, unsigned, failed) */
  filter?: string;
};
export type PostApiConclusionsGenerateDocumentByConclusionIdApiResponse =
  /** status 200 OK */ FileUploadModel;
export type PostApiConclusionsGenerateDocumentByConclusionIdApiArg = {
  /** Идентификатор заключения */
  conclusionId: number;
};
export type PostApiConclusionsSignByConclusionIdApiResponse = unknown;
export type PostApiConclusionsSignByConclusionIdApiArg = {
  /** Идентификатор заключения */
  conclusionId: number;
  /** Идентификатор сертификата */
  certificateId: number;
  body: {
    signFile?: Blob;
  };
};
export type GetApiDoctorsApiResponse = /** status 200 OK */ DoctorModel[];
export type GetApiDoctorsApiArg = {
  /** Активен ли доктор */
  isActive?: boolean;
};
export type PostApiDoctorsApiResponse = unknown;
export type PostApiDoctorsApiArg = {
  /** Модель врача */
  doctorModel: DoctorModel;
};
export type GetApiDoctorsByIdApiResponse =
  /** status 200 OK */ DoctorResponseDto;
export type GetApiDoctorsByIdApiArg = {
  /** Идентификатор врача */
  id: number;
};
export type PutApiDoctorsByIdApiResponse = unknown;
export type PutApiDoctorsByIdApiArg = {
  /** Идентификатор врача */
  id: number;
  /** Модель врача */
  doctorModel: DoctorModel;
};
export type DeleteApiDoctorsByIdApiResponse = unknown;
export type DeleteApiDoctorsByIdApiArg = {
  /** Идентификатор врача */
  id: number;
};
export type GetApiDoctorsGetByPersonIdByPersonIdApiResponse =
  /** status 200 OK */ DoctorModel;
export type GetApiDoctorsGetByPersonIdByPersonIdApiArg = {
  /** Идентификатор физ. лица */
  personId: number;
};
export type GetApiEducationsApiResponse = /** status 200 OK */ EducationModel[];
export type GetApiEducationsApiArg = {
  /** Идентификатор врача */
  doctorId?: number;
};
export type PostApiEducationsApiResponse = unknown;
export type PostApiEducationsApiArg = {
  /** Модель образования */
  educationModel: EducationModel;
};
export type GetApiEducationsByIdApiResponse =
  /** status 200 OK */ EducationModel;
export type GetApiEducationsByIdApiArg = {
  /** Идентификатор образования */
  id: number;
};
export type PutApiEducationsByIdApiResponse = unknown;
export type PutApiEducationsByIdApiArg = {
  /** Идентификатор образования */
  id: number;
  /** Модель образования */
  educationModel: EducationModel;
};
export type DeleteApiEducationsByIdApiResponse = unknown;
export type DeleteApiEducationsByIdApiArg = {
  /** Идентификатор образования */
  id: number;
};
export type GetApiExamsApiResponse =
  /** status 200 OK */ ExamResponseDtoPagedListModelRead;
export type GetApiExamsApiArg = {
  /** Номер требуемой страницы */
  page?: number;
  /** Количество результатов на странице */
  offset?: number;
  /** Строка для поиска (Patient.FirstName, Patient.LastName, Patient.Patronymic) */
  searchInfo?: string;
  /** Строка для сортировки (time, fio, birth, gender, status, type) */
  sort?: string;
  /** Строка для фильтрации (open, closed, unsigned, failed) */
  filter?: string;
  /** Допущен ли обследуемый к поездке по результатам заключения врача */
  isAdmitted?: boolean;
  /** Получить осмотры только за сегодня или за любой день, кроме сегодня */
  isToday?: boolean;
};
export type PostApiExamsApiResponse = unknown;
export type PostApiExamsApiArg = {
  /** Модель осмотра */
  examModel: ExamModel;
};
export type GetApiExamsByIdApiResponse = /** status 200 OK */ ExamResponseDto;
export type GetApiExamsByIdApiArg = {
  /** Идентификатор осмотра */
  id: number;
};
export type PutApiExamsByIdApiResponse = unknown;
export type PutApiExamsByIdApiArg = {
  /** Идентификатор осмотра */
  id: number;
  /** Модель осмотра */
  examModel: ExamModel;
};
export type DeleteApiExamsByIdApiResponse = unknown;
export type DeleteApiExamsByIdApiArg = {
  /** Идентификатор осмотра */
  id: number;
};
export type GetApiExamsGetAllByPatientIdByPatientIdApiResponse =
  /** status 200 OK */ ExamResponseDto[];
export type GetApiExamsGetAllByPatientIdByPatientIdApiArg = {
  /** Идентификатор пациента */
  patientId: number;
  /** Идентификатор крайнего осмотра */
  lastId?: number;
  /** Строка для фильтрации (open, closed, unsigned, failed) */
  filter?: string;
};
export type GetApiExamsCheckStartExamAvailableByPatientIdApiResponse =
  /** status 200 OK */ StartExamAvailableModel;
export type GetApiExamsCheckStartExamAvailableByPatientIdApiArg = {
  /** Идентификатор пациента */
  patientId: number;
};
export type PostApiExamsStartExamApiResponse =
  /** status 200 OK */ StartExamModel;
export type PostApiExamsStartExamApiArg = {
  /** Модель запуска осмотра */
  startExamRequest: StartExamRequest;
};
export type PostApiExamsStopExamByIdApiResponse = unknown;
export type PostApiExamsStopExamByIdApiArg = {
  /** Идентификатор осмотра */
  id: number;
};
export type PutApiExamsChangeExamStatusByIdApiResponse = unknown;
export type PutApiExamsChangeExamStatusByIdApiArg = {
  /** Идентификатор осмотра */
  id: number;
  /** Статус осмотра */
  statusType: ExamStatusEnum;
};
export type GetApiFilesDownloadFileByFileNameApiResponse =
  /** status 200 OK */ FileDownloadUrlModel;
export type GetApiFilesDownloadFileByFileNameApiArg = {
  /** Название файла */
  fileName: string;
};
export type PostApiFilesUploadFileApiResponse =
  /** status 200 OK */ FileUploadModel;
export type PostApiFilesUploadFileApiArg = {
  body: {
    file?: Blob;
  };
};
export type GetApiFilesStaticDocsByTypeApiResponse =
  /** status 200 OK */ FileDownloadUrlModel;
export type GetApiFilesStaticDocsByTypeApiArg = {
  /** Тип документа */
  type: DocsTypeEnum;
};
export type PostApiFillDbCleanAndFillDbApiResponse = unknown;
export type PostApiFillDbCleanAndFillDbApiArg = void;
export type PostApiFillDbCleanDbApiResponse = unknown;
export type PostApiFillDbCleanDbApiArg = void;
export type PostApiFillDbCreateProdUserApiResponse = unknown;
export type PostApiFillDbCreateProdUserApiArg = void;
export type GetApiIPsApiResponse = /** status 200 OK */ IpModel[];
export type GetApiIPsApiArg = void;
export type PostApiIPsApiResponse = unknown;
export type PostApiIPsApiArg = {
  /** Модель ИП */
  ipModel: IpModel;
};
export type GetApiIPsByIdApiResponse = /** status 200 OK */ IpModel;
export type GetApiIPsByIdApiArg = {
  /** Идентификатор ИП */
  id: number;
};
export type PutApiIPsByIdApiResponse = unknown;
export type PutApiIPsByIdApiArg = {
  /** Идентификатор ИП */
  id: number;
  /** Модель ИП */
  ipModel: IpModel;
};
export type DeleteApiIPsByIdApiResponse = unknown;
export type DeleteApiIPsByIdApiArg = {
  /** Идентификатор ИП */
  id: number;
};
export type GetApiLuxmeterApiResponse = /** status 200 OK */ LuxmeterModel[];
export type GetApiLuxmeterApiArg = {
  /** Идентификатор терминала */
  terminalId?: number;
};
export type PostApiLuxmeterApiResponse = /** status 200 OK */ IdResponseDto;
export type PostApiLuxmeterApiArg = {
  /** Модель создания данных люксметра */
  addLuxmetersRequest: AddLuxmetersRequest;
};
export type GetApiLuxmeterGetClosestByTerminalIdApiResponse =
  /** status 200 OK */ LuxmeterModel;
export type GetApiLuxmeterGetClosestByTerminalIdApiArg = {
  /** Дата и время в UTC */
  dateTime: string;
  /** Идентификатор терминала */
  terminalId: number;
};
export type GetApiMediasApiResponse = /** status 200 OK */ MediaModel[];
export type GetApiMediasApiArg = void;
export type PostApiMediasApiResponse = unknown;
export type PostApiMediasApiArg = {
  /** Модель медиа */
  mediaModel: MediaModel;
};
export type GetApiMediasByIdApiResponse = /** status 200 OK */ MediaModel;
export type GetApiMediasByIdApiArg = {
  /** Идентификатор медиа */
  id: number;
};
export type PutApiMediasByIdApiResponse = unknown;
export type PutApiMediasByIdApiArg = {
  /** Идентификатор медиа */
  id: number;
  /** Модель медиа */
  mediaModel: MediaModel;
};
export type DeleteApiMediasByIdApiResponse = unknown;
export type DeleteApiMediasByIdApiArg = {
  /** Идентификатор медиа */
  id: number;
};
export type GetApiMedOrganizationsApiResponse =
  /** status 200 OK */ MedOrganizationModel[];
export type GetApiMedOrganizationsApiArg = void;
export type PostApiMedOrganizationsApiResponse = unknown;
export type PostApiMedOrganizationsApiArg = {
  /** Модель мед. организации */
  medOrganizationModel: MedOrganizationModel;
};
export type GetApiMedOrganizationsByIdApiResponse =
  /** status 200 OK */ MedOrganizationModel;
export type GetApiMedOrganizationsByIdApiArg = {
  /** Идентификатор мед. организации */
  id: number;
};
export type PutApiMedOrganizationsByIdApiResponse = unknown;
export type PutApiMedOrganizationsByIdApiArg = {
  /** Идентификатор мед. организации */
  id: number;
  /** Модель мед. организации */
  medOrganizationModel: MedOrganizationModel;
};
export type DeleteApiMedOrganizationsByIdApiResponse = unknown;
export type DeleteApiMedOrganizationsByIdApiArg = {
  /** Идентификатор мед. организации */
  id: number;
};
export type GetApiMindTestProcsApiResponse =
  /** status 200 OK */ MindTestProcModel[];
export type GetApiMindTestProcsApiArg = void;
export type PostApiMindTestProcsApiResponse =
  /** status 200 OK */ IdResponseDto;
export type PostApiMindTestProcsApiArg = {
  /** Модель создания процедуры */
  addMindTestProcRequest: AddMindTestProcRequest;
};
export type GetApiMindTestProcsByIdApiResponse =
  /** status 200 OK */ MindTestProcModel;
export type GetApiMindTestProcsByIdApiArg = {
  /** Идентификатор процедуры */
  id: number;
};
export type PutApiMindTestProcsByIdApiResponse = unknown;
export type PutApiMindTestProcsByIdApiArg = {
  /** Идентификатор процедуры */
  id: number;
  /** Модель процедуры */
  mindTestProcModel: MindTestProcModel;
};
export type DeleteApiMindTestProcsByIdApiResponse = unknown;
export type DeleteApiMindTestProcsByIdApiArg = {
  /** Идентификатор процедуры */
  id: number;
};
export type GetApiMoTypesApiResponse = /** status 200 OK */ MoTypeModel[];
export type GetApiMoTypesApiArg = void;
export type PostApiMoTypesApiResponse = unknown;
export type PostApiMoTypesApiArg = {
  /** Модель типа МО */
  moTypeModel: MoTypeModel;
};
export type GetApiMoTypesByIdApiResponse = /** status 200 OK */ MoTypeModel;
export type GetApiMoTypesByIdApiArg = {
  /** Идентификатор типа МО */
  id: number;
};
export type PutApiMoTypesByIdApiResponse = unknown;
export type PutApiMoTypesByIdApiArg = {
  /** Идентификатор типа МО */
  id: number;
  /** Модель типа МО */
  moTypeModel: MoTypeModel;
};
export type DeleteApiMoTypesByIdApiResponse = unknown;
export type DeleteApiMoTypesByIdApiArg = {
  /** Идентификатор типа МО */
  id: number;
};
export type GetApiNotificationsApiResponse =
  /** status 200 OK */ NotificationModel[];
export type GetApiNotificationsApiArg = {
  /** Идентификатор физ. лица */
  personId?: number;
};
export type PutApiNotificationsReadByIdApiResponse = unknown;
export type PutApiNotificationsReadByIdApiArg = {
  /** Идентификатор уведомления */
  id: number;
};
export type PutApiNotificationsReadAllByPersonIdApiResponse = unknown;
export type PutApiNotificationsReadAllByPersonIdApiArg = {
  /** Идентификатор физ. лица */
  personId: number;
};
export type DeleteApiNotificationsByIdApiResponse = unknown;
export type DeleteApiNotificationsByIdApiArg = {
  /** Идентификатор уведомления */
  id: number;
};
export type DeleteApiNotificationsDeleteAllByPersonIdApiResponse = unknown;
export type DeleteApiNotificationsDeleteAllByPersonIdApiArg = {
  /** Идентификатор физ. лица */
  personId: number;
};
export type PostApiNotificationsSendTestNotificationApiResponse = unknown;
export type PostApiNotificationsSendTestNotificationApiArg = {
  /** Идентификатор физ. лица */
  personId: number;
};
export type GetApiOrganizationsApiResponse =
  /** status 200 OK */ OrganizationModelPagedListModelRead;
export type GetApiOrganizationsApiArg = {
  /** Номер требуемой страницы */
  page?: number;
  /** Количество результатов на странице */
  offset?: number;
  /** Строка для поиска (name, inn, ogrn) */
  searchInfo?: string;
  /** Строка для сортировки (name, inn, ogrn, email, address, phone) */
  sort?: string;
};
export type PostApiOrganizationsApiResponse = unknown;
export type PostApiOrganizationsApiArg = {
  /** Модель организации */
  organizationModel: OrganizationModel;
};
export type GetApiOrganizationsByIdApiResponse =
  /** status 200 OK */ OrganizationModel;
export type GetApiOrganizationsByIdApiArg = {
  /** Идентификатор организации */
  id: number;
};
export type PutApiOrganizationsByIdApiResponse = unknown;
export type PutApiOrganizationsByIdApiArg = {
  /** Идентификатор организации */
  id: number;
  /** Модель организации */
  organizationModel: OrganizationModel;
};
export type DeleteApiOrganizationsByIdApiResponse = unknown;
export type DeleteApiOrganizationsByIdApiArg = {
  /** Идентификатор организации */
  id: number;
};
export type GetApiOrgConnectsApiResponse =
  /** status 200 OK */ OrgConnectModel[];
export type GetApiOrgConnectsApiArg = void;
export type PostApiOrgConnectsApiResponse = unknown;
export type PostApiOrgConnectsApiArg = {
  /** Модель записи */
  orgConnectModel: OrgConnectModel;
};
export type DeleteApiOrgConnectsApiResponse = unknown;
export type DeleteApiOrgConnectsApiArg = {
  /** Идентификатор организации */
  organizationId: number;
  /** Идентификатор медорганизации */
  medOrganizationId: number;
};
export type GetApiOrgConnectsGetByOrganizationIdByOrganizationIdApiResponse =
  /** status 200 OK */ GetOrgConnectByOrganizationIdResponse;
export type GetApiOrgConnectsGetByOrganizationIdByOrganizationIdApiArg = {
  /** Идентификатор организации */
  organizationId: number;
};
export type GetApiPatientsApiResponse = /** status 200 OK */ PatientModel[];
export type GetApiPatientsApiArg = {
  /** Активен ли обследуемый */
  isActive?: boolean;
};
export type PostApiPatientsApiResponse = unknown;
export type PostApiPatientsApiArg = {
  /** Модель обследуемого */
  patientModel: PatientModel;
};
export type GetApiPatientsByIdApiResponse =
  /** status 200 OK */ PatientResponseDto;
export type GetApiPatientsByIdApiArg = {
  /** Идентификатор обследуемого */
  id: number;
};
export type PutApiPatientsByIdApiResponse = unknown;
export type PutApiPatientsByIdApiArg = {
  /** Идентификатор обследуемого */
  id: number;
  /** Модель обследуемого */
  changePatientRequest: ChangePatientRequest;
};
export type DeleteApiPatientsByIdApiResponse = unknown;
export type DeleteApiPatientsByIdApiArg = {
  /** Идентификатор обследуемого */
  id: number;
};
export type GetApiPatientsGetByPersonalNumberByPersonalNumberApiResponse =
  /** status 200 OK */ PatientResponseDto;
export type GetApiPatientsGetByPersonalNumberByPersonalNumberApiArg = {
  /** Уникальный номер работника */
  personalNumber: string;
};
export type GetApiPatientsGetByPersonIdByPersonIdApiResponse =
  /** status 200 OK */ PatientResponseDto;
export type GetApiPatientsGetByPersonIdByPersonIdApiArg = {
  /** Идентификатор физ. лица */
  personId: number;
};
export type PutApiPatientsChangePatientActivityByIdApiResponse = unknown;
export type PutApiPatientsChangePatientActivityByIdApiArg = {
  /** Идентификатор обследуемого */
  id: number;
  /** Флаг активности пользователя */
  isActive: boolean;
};
export type GetApiPersonPhotosApiResponse =
  /** status 200 OK */ PersonPhotoDto[];
export type GetApiPersonPhotosApiArg = void;
export type GetApiPersonPhotosByIdApiResponse =
  /** status 200 OK */ PersonPhotoDto;
export type GetApiPersonPhotosByIdApiArg = {
  /** Идентификатор фото физ. лица */
  id: number;
};
export type PutApiPersonPhotosByIdApiResponse = unknown;
export type PutApiPersonPhotosByIdApiArg = {
  /** Идентификатор фото физ. лица */
  id: number;
  /** Модель фото физ. лица */
  changePersonPhotoRequest: ChangePersonPhotoRequest;
};
export type DeleteApiPersonPhotosByIdApiResponse = unknown;
export type DeleteApiPersonPhotosByIdApiArg = {
  /** Идентификатор фото физ. лица */
  id: number;
};
export type GetApiPersonPhotosGetByPersonIdByPersonIdApiResponse =
  /** status 200 OK */ PersonPhotoDto[];
export type GetApiPersonPhotosGetByPersonIdByPersonIdApiArg = {
  /** Идентификатор физ. лица */
  personId: number;
};
export type PutApiPersonPhotosApproveRequestApiResponse = unknown;
export type PutApiPersonPhotosApproveRequestApiArg = {
  /** Идентификатор физ. лица */
  personId: number;
};
export type PutApiPersonPhotosDeclineRequestApiResponse = unknown;
export type PutApiPersonPhotosDeclineRequestApiArg = {
  /** Идентификатор физ. лица */
  personId: number;
};
export type PostApiPersonPhotosUploadApiResponse =
  /** status 200 OK */ PersonPhotoDto;
export type PostApiPersonPhotosUploadApiArg = {
  /** Идентификатор физ. лица */
  personId: number;
  body: {
    file: Blob;
  };
};
export type GetApiPersonsApiResponse =
  /** status 200 OK */ PersonModelPagedListModelRead;
export type GetApiPersonsApiArg = {
  /** Номер требуемой страницы */
  page?: number;
  /** Количество результатов на странице */
  offset?: number;
  /** Строка для сортировки (lastname, birthdate, position, gender) */
  sort?: string;
  /** Строка для поиска (FirstName, LastName, Patronymic, Email, Phone) */
  searchInfo?: string;
  /** Роли пользователя (через "+") */
  roleNames?: string;
  /** Статус пользователя */
  userStatus?: UserStatusEnum;
};
export type PostApiPersonsApiResponse = /** status 200 OK */ IdResponseDto;
export type PostApiPersonsApiArg = {
  /** Модель физ. лица */
  addPersonRequest: AddPersonRequest;
};
export type GetApiPersonsByIdApiResponse = /** status 200 OK */ PersonModel;
export type GetApiPersonsByIdApiArg = {
  /** Идентификатор физ. лица */
  id: number;
};
export type PutApiPersonsByIdApiResponse = unknown;
export type PutApiPersonsByIdApiArg = {
  /** Идентификатор физ. лица */
  id: number;
  /** Модель физ. лица */
  changePersonRequest: ChangePersonRequest;
};
export type DeleteApiPersonsByIdApiResponse = unknown;
export type DeleteApiPersonsByIdApiArg = {
  /** Идентификатор физ. лица */
  id: number;
};
export type GetApiPersonsCheckPersonalNumberUniqueByPersonalNumberApiResponse =
  /** status 200 OK */ boolean;
export type GetApiPersonsCheckPersonalNumberUniqueByPersonalNumberApiArg = {
  /** Табельный номер */
  personalNumber: string;
};
export type PutApiPersonsChangeNotificationTokenApiResponse = unknown;
export type PutApiPersonsChangeNotificationTokenApiArg = {
  /** Идентификатор физ. лица */
  id: number;
  /** Токен нотификации */
  notificationToken?: string;
};
export type GetApiPostTripJournalsApiResponse =
  /** status 200 OK */ JournalModel[];
export type GetApiPostTripJournalsApiArg = void;
export type GetApiPostTripJournalsByIdApiResponse =
  /** status 200 OK */ JournalModel;
export type GetApiPostTripJournalsByIdApiArg = {
  /** Идентификатор журнала */
  id: number;
};
export type DeleteApiPostTripJournalsByIdApiResponse = unknown;
export type DeleteApiPostTripJournalsByIdApiArg = {
  /** Идентификатор журнала */
  id: number;
};
export type GetApiPostTripJournalsGenerateJournalDocumentApiResponse =
  /** status 200 OK */ FileDownloadUrlModel;
export type GetApiPostTripJournalsGenerateJournalDocumentApiArg = {
  /** Дата от */
  startDate: string;
  /** Дата до */
  endDate: string;
  /** Формат сформированного документа: 0 - PDF, 1 - XLSX */
  fileExtension?: FileExtensionsEnum;
};
export type PostApiPostTripJournalsSignByIdApiResponse = unknown;
export type PostApiPostTripJournalsSignByIdApiArg = {
  /** Модель подписи */
  signJournalByIdModel: SignJournalByIdModel;
};
export type PostApiPostTripJournalsSignByExamIdApiResponse = unknown;
export type PostApiPostTripJournalsSignByExamIdApiArg = {
  /** Модель подписи */
  signJournalByExamIdModel: SignJournalByExamIdModel;
};
export type GetApiPostTripJournalsGetHashByExamIdByExamIdApiResponse =
  /** status 200 OK */ HashModel;
export type GetApiPostTripJournalsGetHashByExamIdByExamIdApiArg = {
  /** Идентификатор осмотра */
  examId: number;
};
export type GetApiPostTripJournalsGetHashByIdByIdApiResponse =
  /** status 200 OK */ HashModel;
export type GetApiPostTripJournalsGetHashByIdByIdApiArg = {
  /** Идентификатор журнала */
  id: number;
};
export type GetApiPreTripJournalsApiResponse =
  /** status 200 OK */ JournalModel[];
export type GetApiPreTripJournalsApiArg = void;
export type GetApiPreTripJournalsByIdApiResponse =
  /** status 200 OK */ JournalModel;
export type GetApiPreTripJournalsByIdApiArg = {
  /** Идентификатор журнала */
  id: number;
};
export type DeleteApiPreTripJournalsByIdApiResponse = unknown;
export type DeleteApiPreTripJournalsByIdApiArg = {
  /** Идентификатор журнала */
  id: number;
};
export type GetApiPreTripJournalsGenerateJournalDocumentApiResponse =
  /** status 200 OK */ FileDownloadUrlModel;
export type GetApiPreTripJournalsGenerateJournalDocumentApiArg = {
  /** Дата от */
  startDate: string;
  /** Дата до */
  endDate: string;
  /** Формат сформированного документа: 0 - PDF, 1 - XLSX */
  fileExtension?: FileExtensionsEnum;
};
export type PostApiPreTripJournalsSignByIdApiResponse = unknown;
export type PostApiPreTripJournalsSignByIdApiArg = {
  /** Модель подписи */
  signJournalByIdModel: SignJournalByIdModel;
};
export type PostApiPreTripJournalsSignByExamIdApiResponse = unknown;
export type PostApiPreTripJournalsSignByExamIdApiArg = {
  /** Модель подписи */
  signJournalByExamIdModel: SignJournalByExamIdModel;
};
export type GetApiPreTripJournalsGetHashByExamIdByExamIdApiResponse =
  /** status 200 OK */ HashModel;
export type GetApiPreTripJournalsGetHashByExamIdByExamIdApiArg = {
  /** Идентификатор осмотра */
  examId: number;
};
export type GetApiPreTripJournalsGetHashByIdByIdApiResponse =
  /** status 200 OK */ HashModel;
export type GetApiPreTripJournalsGetHashByIdByIdApiArg = {
  /** Идентификатор журнала */
  id: number;
};
export type GetApiProcedureDevicesApiResponse =
  /** status 200 OK */ ProcedureDeviceDto[];
export type GetApiProcedureDevicesApiArg = void;
export type PostApiProcedureDevicesApiResponse =
  /** status 200 OK */ IdResponseDto;
export type PostApiProcedureDevicesApiArg = {
  /** Модель устройства */
  addProcedureDeviceRequest: AddProcedureDeviceRequest;
};
export type GetApiProcedureDevicesByIdApiResponse =
  /** status 200 OK */ ProcedureDeviceDto;
export type GetApiProcedureDevicesByIdApiArg = {
  /** Идентификатор устройства */
  id: number;
};
export type PutApiProcedureDevicesByIdApiResponse = unknown;
export type PutApiProcedureDevicesByIdApiArg = {
  /** Идентификатор устройства */
  id: number;
  /** Модель устройства */
  procedureDeviceDto: ProcedureDeviceDto;
};
export type DeleteApiProcedureDevicesByIdApiResponse = unknown;
export type DeleteApiProcedureDevicesByIdApiArg = {
  /** Идентификатор устройства */
  id: number;
};
export type GetApiProcedureDevicesGetByNameByNameApiResponse =
  /** status 200 OK */ ProcedureDeviceDto;
export type GetApiProcedureDevicesGetByNameByNameApiArg = {
  /** Название устройства */
  name: string;
};
export type GetApiQuestionsApiResponse = /** status 200 OK */ QuestionModel[];
export type GetApiQuestionsApiArg = void;
export type PostApiQuestionsApiResponse = unknown;
export type PostApiQuestionsApiArg = {
  /** Модель вопроса */
  questionModel: QuestionModel;
};
export type GetApiQuestionsByIdApiResponse = /** status 200 OK */ QuestionModel;
export type GetApiQuestionsByIdApiArg = {
  /** Идентификатор вопроса */
  id: number;
};
export type PutApiQuestionsByIdApiResponse = unknown;
export type PutApiQuestionsByIdApiArg = {
  /** Идентификатор вопроса */
  id: number;
  /** Модель вопроса */
  questionModel: QuestionModel;
};
export type DeleteApiQuestionsByIdApiResponse = unknown;
export type DeleteApiQuestionsByIdApiArg = {
  /** Идентификатор вопроса */
  id: number;
};
export type GetApiQuizesApiResponse = /** status 200 OK */ QuizModel[];
export type GetApiQuizesApiArg = void;
export type PostApiQuizesApiResponse = /** status 200 OK */ IdResponseDto;
export type PostApiQuizesApiArg = {
  /** Модель создания опроса */
  addQuizRequest: AddQuizRequest;
};
export type GetApiQuizesByIdApiResponse = /** status 200 OK */ QuizModel;
export type GetApiQuizesByIdApiArg = {
  /** Идентификатор опроса */
  id: number;
};
export type PutApiQuizesByIdApiResponse = unknown;
export type PutApiQuizesByIdApiArg = {
  /** Идентификатор опроса */
  id: number;
  /** Модель опроса */
  quizModel: QuizModel;
};
export type DeleteApiQuizesByIdApiResponse = unknown;
export type DeleteApiQuizesByIdApiArg = {
  /** Идентификатор опроса */
  id: number;
};
export type GetApiReasonsApiResponse = /** status 200 OK */ ReasonModel[];
export type GetApiReasonsApiArg = void;
export type PostApiReasonsApiResponse = unknown;
export type PostApiReasonsApiArg = {
  /** Модель причины */
  reasonModel: ReasonModel;
};
export type GetApiReasonsByIdApiResponse = /** status 200 OK */ ReasonModel;
export type GetApiReasonsByIdApiArg = {
  /** Идентификатор причины */
  id: number;
};
export type PutApiReasonsByIdApiResponse = unknown;
export type PutApiReasonsByIdApiArg = {
  /** Идентификатор причины */
  id: number;
  /** Модель причины */
  reasonModel: ReasonModel;
};
export type DeleteApiReasonsByIdApiResponse = unknown;
export type DeleteApiReasonsByIdApiArg = {
  /** Идентификатор причины */
  id: number;
};
export type GetApiReferenceIndicatorsApiResponse =
  /** status 200 OK */ ReferenceIndicatorDto[];
export type GetApiReferenceIndicatorsApiArg = void;
export type PostApiReferenceIndicatorsApiResponse =
  /** status 200 OK */ IdResponseDto;
export type PostApiReferenceIndicatorsApiArg = {
  /** Модель создания референсных значений */
  addReferenceIndicatorRequest: AddReferenceIndicatorRequest;
};
export type GetApiReferenceIndicatorsByIdApiResponse =
  /** status 200 OK */ ReferenceIndicatorDto;
export type GetApiReferenceIndicatorsByIdApiArg = {
  /** Идентификатор референсных значений */
  id: number;
};
export type PutApiReferenceIndicatorsByIdApiResponse = unknown;
export type PutApiReferenceIndicatorsByIdApiArg = {
  /** Идентификатор референсных значений */
  id: number;
  /** Модель референсных значений */
  referenceIndicatorDto: ReferenceIndicatorDto;
};
export type DeleteApiReferenceIndicatorsByIdApiResponse = unknown;
export type DeleteApiReferenceIndicatorsByIdApiArg = {
  /** Идентификатор референсных значений */
  id: number;
};
export type GetApiReferenceIndicatorsGetByPatientIdByPatientIdApiResponse =
  /** status 200 OK */ ReferenceIndicatorDto;
export type GetApiReferenceIndicatorsGetByPatientIdByPatientIdApiArg = {
  /** Идентификатор обследуемого */
  patientId: number;
};
export type GetApiReportsGeneratePivotTableApiResponse =
  /** status 200 OK */ Blob;
export type GetApiReportsGeneratePivotTableApiArg = {
  /** Дата от */
  startDate: string;
  /** Дата до */
  endDate: string;
};
export type GetApiReportsGenerateExamReportApiResponse =
  /** status 200 OK */ Blob;
export type GetApiReportsGenerateExamReportApiArg = {
  /** Дата от */
  startDate: string;
  /** Дата до */
  endDate: string;
};
export type GetApiRolesApiResponse = /** status 200 OK */ RoleModel[];
export type GetApiRolesApiArg = void;
export type PostApiRolesApiResponse = unknown;
export type PostApiRolesApiArg = {
  /** Модель роли */
  roleModel: RoleModel;
};
export type GetApiRolesByIdApiResponse = /** status 200 OK */ RoleModel;
export type GetApiRolesByIdApiArg = {
  /** Идентификатор роли */
  id: number;
};
export type PutApiRolesByIdApiResponse = unknown;
export type PutApiRolesByIdApiArg = {
  /** Идентификатор роли */
  id: number;
  /** Модель роли */
  roleModel: RoleModel;
};
export type DeleteApiRolesByIdApiResponse = unknown;
export type DeleteApiRolesByIdApiArg = {
  /** Идентификатор роли */
  id: number;
};
export type GetApiRolesGetByNameByNameApiResponse =
  /** status 200 OK */ RoleModel;
export type GetApiRolesGetByNameByNameApiArg = {
  /** Название роли */
  name: string;
};
export type GetApiSchedulesApiResponse = /** status 200 OK */ ScheduleModel[];
export type GetApiSchedulesApiArg = void;
export type PostApiSchedulesApiResponse = unknown;
export type PostApiSchedulesApiArg = {
  /** Модель расписания */
  scheduleModel: ScheduleModel;
};
export type GetApiSchedulesByIdApiResponse = /** status 200 OK */ ScheduleModel;
export type GetApiSchedulesByIdApiArg = {
  /** Идентификатор расписания */
  id: number;
};
export type PutApiSchedulesByIdApiResponse = unknown;
export type PutApiSchedulesByIdApiArg = {
  /** Идентификатор расписания */
  id: number;
  /** Модель расписания */
  scheduleModel: ScheduleModel;
};
export type DeleteApiSchedulesByIdApiResponse = unknown;
export type DeleteApiSchedulesByIdApiArg = {
  /** Идентификатор расписания */
  id: number;
};
export type GetApiSpecialitiesApiResponse =
  /** status 200 OK */ SpecialityModel[];
export type GetApiSpecialitiesApiArg = void;
export type GetApiSpecialitiesByIdApiResponse =
  /** status 200 OK */ SpecialityModel;
export type GetApiSpecialitiesByIdApiArg = {
  /** Идентификатор специальности */
  id: number;
};
export type GetApiSpeechTestProcsApiResponse =
  /** status 200 OK */ SpeechTestProcModel[];
export type GetApiSpeechTestProcsApiArg = void;
export type PostApiSpeechTestProcsApiResponse =
  /** status 200 OK */ IdResponseDto;
export type PostApiSpeechTestProcsApiArg = {
  /** Модель создания процедуры */
  addSpeechTestProcRequest: AddSpeechTestProcRequest;
};
export type GetApiSpeechTestProcsByIdApiResponse =
  /** status 200 OK */ SpeechTestProcModel;
export type GetApiSpeechTestProcsByIdApiArg = {
  /** Идентификатор процедуры */
  id: number;
};
export type PutApiSpeechTestProcsByIdApiResponse = unknown;
export type PutApiSpeechTestProcsByIdApiArg = {
  /** Идентификатор процедуры */
  id: number;
  /** Модель процедуры */
  speechTestProcModel: SpeechTestProcModel;
};
export type DeleteApiSpeechTestProcsByIdApiResponse = unknown;
export type DeleteApiSpeechTestProcsByIdApiArg = {
  /** Идентификатор процедуры */
  id: number;
};
export type GetApiTemperatureProcsApiResponse =
  /** status 200 OK */ TemperatureProcModel[];
export type GetApiTemperatureProcsApiArg = void;
export type PostApiTemperatureProcsApiResponse =
  /** status 200 OK */ IdResponseDto;
export type PostApiTemperatureProcsApiArg = {
  /** Модель процедуры */
  temperatureProcModel: TemperatureProcModel;
};
export type GetApiTemperatureProcsByIdApiResponse =
  /** status 200 OK */ TemperatureProcModel;
export type GetApiTemperatureProcsByIdApiArg = {
  /** Идентификатор процедуры */
  id: number;
};
export type PutApiTemperatureProcsByIdApiResponse = unknown;
export type PutApiTemperatureProcsByIdApiArg = {
  /** Идентификатор процедуры */
  id: number;
  /** Модель процедуры */
  temperatureProcModel: TemperatureProcModel;
};
export type DeleteApiTemperatureProcsByIdApiResponse = unknown;
export type DeleteApiTemperatureProcsByIdApiArg = {
  /** Идентификатор процедуры */
  id: number;
};
export type GetApiTemperatureProcsGetByExamIdAsyncByExamIdApiResponse =
  /** status 200 OK */ TemperatureProcModel[];
export type GetApiTemperatureProcsGetByExamIdAsyncByExamIdApiArg = {
  /** Идентификатор осмотра */
  examId: number;
};
export type PostApiTemperatureProcsStartTempProcApiResponse =
  /** status 200 OK */ IdResponseDto;
export type PostApiTemperatureProcsStartTempProcApiArg = {
  /** Модель запуска процедуры */
  startTempProcRequest: StartTempProcRequest;
};
export type PostApiTemperatureProcsStartAlcoAndTempApiResponse =
  /** status 200 OK */ AlcoAndTermoModel;
export type PostApiTemperatureProcsStartAlcoAndTempApiArg = {
  /** Модель запуска процедуры */
  startTempProcRequest: StartTempProcRequest;
};
export type GetApiTerminalsApiResponse =
  /** status 200 OK */ TerminalModelPagedListModelRead;
export type GetApiTerminalsApiArg = {
  /** Номер требуемой страницы */
  page?: number;
  /** Количество результатов на странице */
  offset?: number;
  /** Строка для сортировки (addr, loc, serial, timezone) */
  sort?: string;
  /** Строка для поиска (SerialNumber, Address, TerminalLocation) */
  searchInfo?: string;
  /** Статус терминала */
  status?: TerminalStatusEnum;
};
export type PostApiTerminalsApiResponse = /** status 200 OK */ IdResponseDto;
export type PostApiTerminalsApiArg = {
  /** Модель терминала */
  addTerminalRequest: AddTerminalRequest;
};
export type GetApiTerminalsByIdApiResponse = /** status 200 OK */ TerminalModel;
export type GetApiTerminalsByIdApiArg = {
  /** Идентификатор терминала */
  id: number;
};
export type PutApiTerminalsByIdApiResponse = unknown;
export type PutApiTerminalsByIdApiArg = {
  /** Идентификатор терминала */
  id: number;
  /** Модель терминала */
  changeTerminalRequest: ChangeTerminalRequest;
};
export type DeleteApiTerminalsByIdApiResponse = unknown;
export type DeleteApiTerminalsByIdApiArg = {
  /** Идентификатор терминала */
  id: number;
};
export type GetApiTerminalsGetBySerialNumberBySerialNumberApiResponse =
  /** status 200 OK */ TerminalModel;
export type GetApiTerminalsGetBySerialNumberBySerialNumberApiArg = {
  /** Серийный номер терминала */
  serialNumber: string;
};
export type PostApiTerminalsByIdRebootApiResponse = unknown;
export type PostApiTerminalsByIdRebootApiArg = {
  /** Идентификатор терминала */
  id: number;
};
export type PutApiTerminalsChangeTerminalStatusApiResponse = unknown;
export type PutApiTerminalsChangeTerminalStatusApiArg = {
  /** Идентификатор терминала */
  id: number;
  /** Статус терминала */
  status: TerminalStatusEnum;
};
export type PutApiTerminalsByIdChangeDevicesApiResponse = unknown;
export type PutApiTerminalsByIdChangeDevicesApiArg = {
  /** Идентификатор терминала */
  id: number;
  changeTerminalDevicesRequest: ChangeTerminalDevicesRequest;
};
export type GetApiTermoGigrsApiResponse = /** status 200 OK */ TermoGigrModel[];
export type GetApiTermoGigrsApiArg = void;
export type PostApiTermoGigrsApiResponse = unknown;
export type PostApiTermoGigrsApiArg = {
  /** Модель данных термогигрометра */
  termoGigrModel: TermoGigrModel;
};
export type GetApiTermoGigrsGetAllByTerminalIdTerminalIdApiResponse =
  /** status 200 OK */ TermoGigrModel[];
export type GetApiTermoGigrsGetAllByTerminalIdTerminalIdApiArg = {
  /** Идентификатор терминала */
  terminalId: number;
};
export type GetApiTermoGigrsGetClosestByTerminalIdApiResponse =
  /** status 200 OK */ TermoGigrModel;
export type GetApiTermoGigrsGetClosestByTerminalIdApiArg = {
  /** Дата в UTC */
  dateTime: string;
  /** Идентификатор терминала */
  terminalId: number;
};
export type GetApiUserRolesApiResponse = /** status 200 OK */ UserRoleModel[];
export type GetApiUserRolesApiArg = void;
export type PostApiUserRolesApiResponse = unknown;
export type PostApiUserRolesApiArg = {
  /** Модель сущности */
  addUserRoleRequest: AddUserRoleRequest;
};
export type GetApiUserRolesGetAllByUserIdByUserIdApiResponse =
  /** status 200 OK */ UserRoleModel[];
export type GetApiUserRolesGetAllByUserIdByUserIdApiArg = {
  /** Идентификатор пользователя */
  userId: number;
};
export type GetApiUserRolesGetAllByRoleIdByRoleIdApiResponse =
  /** status 200 OK */ UserRoleModel[];
export type GetApiUserRolesGetAllByRoleIdByRoleIdApiArg = {
  /** Идентификатор роли */
  roleId: number;
};
export type GetApiUserRolesUserIdRoleIdApiResponse =
  /** status 200 OK */ UserRoleModel;
export type GetApiUserRolesUserIdRoleIdApiArg = {
  /** Идентификатор пользователя */
  userId: number;
  /** Идентификатор роли */
  roleId: number;
};
export type DeleteApiUserRolesUserIdRoleIdApiResponse = unknown;
export type DeleteApiUserRolesUserIdRoleIdApiArg = {
  /** Идентификатор пользователя */
  userId: number;
  /** Идентификатор роли */
  roleId: number;
};
export type GetApiUsersApiResponse =
  /** status 200 OK */ UserModelPagedListModelRead;
export type GetApiUsersApiArg = {
  /** Номер требуемой страницы */
  page?: number;
  /** Количество результатов на странице */
  offset?: number;
  /** Строка для сортировки (name) */
  sort?: string;
  /** Строка для поиска (UserName) */
  searchInfo?: string;
  /** Роли пользователя (через "+") */
  roleNames?: string;
};
export type PostApiUsersApiResponse = /** status 200 OK */ IdResponseDto;
export type PostApiUsersApiArg = {
  /** Модель пользователя */
  addUserRequest: AddUserRequest;
};
export type GetApiUsersByIdApiResponse = /** status 200 OK */ UserModel;
export type GetApiUsersByIdApiArg = {
  /** Идентификатор пользователя */
  id: number;
};
export type PutApiUsersByIdApiResponse = unknown;
export type PutApiUsersByIdApiArg = {
  /** Идентификатор пользователя */
  id: number;
  /** Модель пользователя */
  changeUserRequest: ChangeUserRequest;
};
export type DeleteApiUsersByIdApiResponse = unknown;
export type DeleteApiUsersByIdApiArg = {
  /** Идентификатор пользователя */
  id: number;
};
export type GetApiUsersCheckUserNameUniqueByUserNameApiResponse =
  /** status 200 OK */ boolean;
export type GetApiUsersCheckUserNameUniqueByUserNameApiArg = {
  /** Имя пользователя */
  userName: string;
};
export type PutApiUsersChangeUserStatusApiResponse = unknown;
export type PutApiUsersChangeUserStatusApiArg = {
  /** Идентификатор пользователя */
  userId: number;
  /** Статус пользователя */
  status: UserStatusEnum;
};
export type PutApiUsersChangePasswordApiResponse = unknown;
export type PutApiUsersChangePasswordApiArg = {
  /** Модель запроса */
  changePasswordRequest: ChangePasswordRequest;
};
export type PutApiUsersResetPasswordApiResponse = unknown;
export type PutApiUsersResetPasswordApiArg = {
  /** Модель запроса */
  resetPasswordRequest: ResetPasswordRequest;
};
export type GetApiWorkPlacesApiResponse = /** status 200 OK */ WorkPlaceModel[];
export type GetApiWorkPlacesApiArg = {
  /** Идентификатор врача */
  doctorId?: number;
};
export type PostApiWorkPlacesApiResponse = unknown;
export type PostApiWorkPlacesApiArg = {
  /** Модель места работы */
  workPlaceModel: WorkPlaceModel;
};
export type GetApiWorkPlacesByIdApiResponse =
  /** status 200 OK */ WorkPlaceModel;
export type GetApiWorkPlacesByIdApiArg = {
  /** Идентификатор места работы */
  id: number;
};
export type PutApiWorkPlacesByIdApiResponse = unknown;
export type PutApiWorkPlacesByIdApiArg = {
  /** Идентификатор места работы */
  id: number;
  /** Модель места работы */
  workPlaceModel: WorkPlaceModel;
};
export type DeleteApiWorkPlacesByIdApiResponse = unknown;
export type DeleteApiWorkPlacesByIdApiArg = {
  /** Идентифкатор места работы */
  id: number;
};
export type AlcoUnitTypeEnum = 0 | 1;
export type AlcoTestProcModel = {
  id: number;
  examId: number;
  isSuccess: boolean;
  reason?: string | null;
  procDate: string;
  alco: number;
  alcoUnitType: AlcoUnitTypeEnum;
};
export type IdResponseDto = {
  id: number;
};
export type StartAlcoProcRequest = {
  examId: number;
  alcoUnitType: AlcoUnitTypeEnum;
};
export type AlcoAndTermoModel = {
  alcoProcId: number;
  temperatureProcId: number;
};
export type AnswerModel = {
  id: number;
  questionId: number;
  quizId: number;
  ynAnswer?: boolean | null;
  value?: string | null;
};
export type ApiKeyDto = {
  terminalApiKey: string;
  deviceApiKey: string;
  terminalId: number;
};
export type CreateApiKeyModel = {
  terminalApiKey: string;
  deviceApiKey: string;
  serialNumber: string;
};
export type TokenModel = {
  access?: string | null;
  refresh?: string | null;
};
export type AuthModel = {
  login?: string | null;
  password?: string | null;
};
export type AccessTokenDto = {
  access?: string | null;
};
export type BloodPressProcModel = {
  id: number;
  examId: number;
  isSuccess: boolean;
  reason?: string | null;
  procDate: string;
  systPres: number;
  diastPres: number;
  pulse: number;
};
export type StartBloodProcRequest = {
  examId: number;
};
export type CertificateModel = {
  id: number;
  description: string;
  isActive: boolean;
  issuer?: string | null;
  startDate: string;
  endDate: string;
  personId: number;
  subject?: string | null;
  thumbprint: string;
  x509Certificate: string;
};
export type ConclusionModel = {
  id: number;
  examId: number;
  patientId: number;
  isAdmitted: boolean;
  reasonId?: number | null;
  reportDate: string;
  doctorId: number;
  fileName?: string | null;
  signFileName?: string | null;
  certificateId?: number | null;
  reasonComment?: string | null;
};
export type ReasonDto = {
  id: number;
  value: string;
};
export type GenderTypeEnum = 0 | 1;
export type UserStatusEnum = 0 | 1;
export type AppUserDto = {
  id: number;
  userName: string;
  status: UserStatusEnum;
};
export type PersonPhotoStatusEnum = 0 | 1 | 2;
export type PersonPhotoDto = {
  id: number;
  fileName: string;
  status: PersonPhotoStatusEnum;
  personId: number;
};
export type PersonResponseDto = {
  id: number;
  lastName: string;
  firstName: string;
  patronymic?: string | null;
  birthDate: string;
  gender: GenderTypeEnum;
  email: string;
  phone: string;
  personalNumber?: string | null;
  position: string;
  userId: number;
  user: AppUserDto;
  personPhotos: PersonPhotoDto[];
};
export type OrganizationDto = {
  id: number;
  name: string;
  inn?: string | null;
  ogrn?: string | null;
  isIp: boolean;
  contactFIO: string;
  contactPost?: string | null;
  contactPhone?: string | null;
  address: string;
  phone: string;
  email?: string | null;
  isPersonPhotoRequired: boolean;
};
export type PatientResponseDto = {
  id: number;
  driveLicense: string;
  isActive: boolean;
  personId: number;
  person: PersonResponseDto;
  organizationId?: number | null;
  organization?: OrganizationDto;
};
export type QualificationTypeEnum = 0 | 1 | 2;
export type ScientificDegreeTypeEnum = 0 | 1;
export type DoctorResponseDto = {
  id: number;
  qualificationType?: QualificationTypeEnum;
  scientificDegreeType?: ScientificDegreeTypeEnum;
  workExperience?: number | null;
  isActive: boolean;
  personId: number;
  person?: PersonResponseDto;
};
export type ConclusionResponseDto = {
  id: number;
  examId: number;
  isAdmitted: boolean;
  reasonId?: number | null;
  reason?: ReasonDto;
  reportDate: string;
  patientId: number;
  patient?: PatientResponseDto;
  doctorId: number;
  doctor?: DoctorResponseDto;
  fileName?: string | null;
  signFileName?: string | null;
  certificateId?: number | null;
  reasonComment?: string | null;
};
export type FileUploadModel = {
  fileName?: string | null;
};
export type DoctorModel = {
  id: number;
  personId: number;
  qualificationType?: QualificationTypeEnum;
  scientificDegreeType?: ScientificDegreeTypeEnum;
  workExperience?: number | null;
  medOrganizationId?: number | null;
  isActive: boolean;
};
export type EducationTypeEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type EducationModel = {
  id: number;
  doctorId: number;
  universityName: string;
  educationType: EducationTypeEnum;
  specialityId?: number | null;
  startDate: string;
  endDate: string;
};
export type ExamStatusEnum = 0 | 1 | 2 | 3 | 4;
export type ExamTypeEnum = 0 | 1 | 2 | 3;
export type TerminalStatusEnum = 0 | 1;
export type QuizTypeEnum = 0 | 1;
export type TerminalDto = {
  id: number;
  serialNumber: string;
  status: TerminalStatusEnum;
  isMobile: boolean;
  hasHints: boolean;
  quizType: QuizTypeEnum;
  hasSpeechTest: boolean;
  hasMindTest: boolean;
  terminalLocation?: string | null;
  address: string;
};
export type PersonPhoto = {
  id: number;
  fileName: string;
  status: PersonPhotoStatusEnum;
  personId: number;
};
export type PersonDto = {
  id: number;
  lastName: string;
  firstName: string;
  patronymic?: string | null;
  birthDate: string;
  gender: GenderTypeEnum;
  email: string;
  phone: string;
  personalNumber?: string | null;
  position: string;
  notificationToken?: string | null;
  personPhotos?: PersonPhoto[] | null;
};
export type PatientDto = {
  id: number;
  driveLicense?: string | null;
  isActive?: boolean;
  personId: number;
  person: PersonDto;
};
export type AnswerDto = {
  id: number;
  ynAnswer?: boolean | null;
  value?: string | null;
  questionId: number;
};
export type QuizDto = {
  id: number;
  quizType: QuizTypeEnum;
  procDate: string;
  answers?: AnswerDto[] | null;
};
export type DoctorDto = {
  id: number;
  qualificationType?: QualificationTypeEnum;
  scientificDegreeType?: ScientificDegreeTypeEnum;
  workExperience?: number | null;
  isActive: boolean;
  personId: number;
  person: PersonDto;
};
export type CertificateDto = {
  id: number;
  description: string;
  isActive: boolean;
  issuer?: string | null;
  startDate: string;
  endDate: string;
  subject?: string | null;
  thumbprint: string;
  personId: number;
};
export type ConclusionDto = {
  id: number;
  isAdmitted: boolean;
  reportDate: string;
  fileName?: string | null;
  signFileName?: string | null;
  doctorId: number;
  doctor: DoctorDto;
  certificateId?: number | null;
  certificate?: CertificateDto;
  reasonId?: number | null;
  reason?: ReasonDto;
};
export type SpeechTestProcDto = {
  id: number;
  procDate: string;
  audio: string;
};
export type MindTestResultTypeEnum = 0 | 1 | 2;
export type MindTestProcDto = {
  id: number;
  procDate: string;
  elapsedTime: number;
  mistakes: number;
  scores: number;
  resultType: MindTestResultTypeEnum;
};
export type BloodPressProcDto = {
  id: number;
  isSuccess: boolean;
  reason?: string | null;
  procDate: string;
  systPres: number;
  diastPres: number;
  pulse: number;
};
export type AlcoTestProcDto = {
  id: number;
  isSuccess: boolean;
  reason?: string | null;
  procDate: string;
  alco: number;
  alcoUnitType: AlcoUnitTypeEnum;
};
export type TempUnitTypeEnum = 0 | 1;
export type TemperatureProcDto = {
  id: number;
  isSuccess: boolean;
  reason?: string | null;
  procDate: string;
  temperature: number;
  tempUnitType: TempUnitTypeEnum;
};
export type MediaTypeEnum = 0 | 1 | 2;
export type MediaStageEnum = 0 | 1 | 2 | 3;
export type MediaDto = {
  id: number;
  storageId: string;
  mediaType: MediaTypeEnum;
  stage?: MediaStageEnum;
};
export type ExamResponseDto = {
  id: number;
  startDate: string;
  endDate?: string | null;
  statusType: ExamStatusEnum;
  agreement: string;
  examType: ExamTypeEnum;
  terminalId: number;
  terminal: TerminalDto;
  patientId: number;
  patient: PatientDto;
  quizId?: number | null;
  quiz?: QuizDto;
  conclusionId?: number | null;
  conclusion?: ConclusionDto;
  speechTestProcs: SpeechTestProcDto[];
  mindTestProcs: MindTestProcDto[];
  bloodPressureProcs: BloodPressProcDto[];
  alcoTestProcs: AlcoTestProcDto[];
  temperatureProcs: TemperatureProcDto[];
  medias: MediaDto[];
};
export type ExamResponseDtoPagedListModel = {
  page?: number | null;
  offset?: number | null;
  totalPages?: number | null;
  totalResults?: number | null;
  models?: ExamResponseDto[] | null;
};
export type ExamResponseDtoPagedListModelRead = {
  page?: number | null;
  offset?: number | null;
  totalPages?: number | null;
  totalResults?: number | null;
  models?: ExamResponseDto[] | null;
  hasNextPage?: boolean | null;
  hasPreviousPage?: boolean | null;
};
export type ExamModel = {
  id: number;
  terminalId: number;
  patientId: number;
  startDate: string;
  endDate?: string | null;
  statusType: ExamStatusEnum;
  agreement: string;
  examType: ExamTypeEnum;
};
export type StartExamAvailableModel = {
  preTrip?: boolean;
  postTrip?: boolean;
  preShift?: boolean;
  postShift?: boolean;
};
export type ProcedureDeviceTypeEnum = 0 | 1 | 2;
export type ProcedureDeviceDto = {
  id: number;
  name: string;
  type: ProcedureDeviceTypeEnum;
  isManualStart: boolean;
  isSoundOn: boolean;
  timeout: number;
};
export type TerminalModel = {
  id: number;
  serialNumber: string;
  status: TerminalStatusEnum;
  isMobile: boolean;
  hasHints: boolean;
  quizType: QuizTypeEnum;
  hasSpeechTest: boolean;
  hasMindTest: boolean;
  terminalLocation?: string | null;
  address: string;
  alcoJoinTemp: boolean;
  sendVideoEnabled: boolean;
  timezone: number;
  alcotesterDevice?: ProcedureDeviceDto;
  thermometorDevice?: ProcedureDeviceDto;
  tonometerDevice?: ProcedureDeviceDto;
};
export type ExamParamResponse = {
  id?: number;
  terminal?: TerminalModel;
  quiz?: QuizDto;
};
export type StartExamModel = {
  isSuccess?: boolean;
  reason?: string | null;
  exam?: ExamParamResponse;
  rabbitHostName?: string | null;
  rabbitVirtualHost?: string | null;
};
export type StartExamRequest = {
  terminalId: number;
  patientId: number;
  examType: ExamTypeEnum;
};
export type FileDownloadUrlModel = {
  url?: string | null;
};
export type DocsTypeEnum = 1 | 2;
export type IpModel = {
  id: number;
  personId: number;
  ogrnIp: string;
  inn?: string | null;
  addressId: number;
};
export type LuxmeterModel = {
  id: number;
  scanDate: string;
  luminosity: number;
  terminalId: number;
};
export type AddLuxmetersRequest = {
  scanDate: string;
  luminosity: number;
  terminalId: number;
};
export type MediaModel = {
  id: number;
  examId: number;
  storageId: string;
  mediaType: MediaTypeEnum;
  stage?: MediaStageEnum;
};
export type MedOrganizationModel = {
  name: string;
  inn?: string | null;
  ogrn?: string | null;
  isIp: boolean;
  contactFIO: string;
  contactPost?: string | null;
  contactPhone?: string | null;
  address: string;
  phone: string;
  email?: string | null;
  id: number;
  medLicence: string;
  recReestrMO?: string | null;
  numReestrMO?: string | null;
  moTypeId?: number | null;
};
export type MindTestProcModel = {
  id: number;
  examId: number;
  procDate: string;
  elapsedTime: number;
  mistakes: number;
  scores: number;
  resultType: MindTestResultTypeEnum;
};
export type AddMindTestProcRequest = {
  procDate: string;
  elapsedTime: number;
  mistakes: number;
  scores: number;
  resultType: MindTestResultTypeEnum;
  examId: number;
};
export type MedicalWorkTypeEnum = 0 | 1 | 2;
export type MoTypeModel = {
  id: number;
  name: string;
  medicalWorkType: MedicalWorkTypeEnum;
};
export type NotificationTypeEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type NotificationModel = {
  id: number;
  personId: number;
  messageJson: string;
  isRead: boolean;
  type: NotificationTypeEnum;
  creationDate: string;
};
export type OrganizationModel = {
  name: string;
  inn?: string | null;
  ogrn?: string | null;
  isIp: boolean;
  contactFIO: string;
  contactPost?: string | null;
  contactPhone?: string | null;
  address: string;
  phone: string;
  email?: string | null;
  id: number;
  isPersonPhotoRequired: boolean;
};
export type OrganizationModelPagedListModel = {
  page?: number | null;
  offset?: number | null;
  totalPages?: number | null;
  totalResults?: number | null;
  models?: OrganizationModel[] | null;
};
export type OrganizationModelPagedListModelRead = {
  page?: number | null;
  offset?: number | null;
  totalPages?: number | null;
  totalResults?: number | null;
  models?: OrganizationModel[] | null;
  hasNextPage?: boolean | null;
  hasPreviousPage?: boolean | null;
};
export type OrgConnectModel = {
  medOrganizationId: number;
  organizationId: number;
};
export type MoTypeDto = {
  name: string;
  medicalWorkType: MedicalWorkTypeEnum;
};
export type MedOrganizationDto = {
  id: number;
  name: string;
  inn?: string | null;
  ogrn?: string | null;
  isIp: boolean;
  contactFIO: string;
  contactPost?: string | null;
  contactPhone?: string | null;
  address: string;
  phone: string;
  email?: string | null;
  medLicence: string;
  recReestrMO?: string | null;
  numReestrMO?: string | null;
  moType?: MoTypeDto;
};
export type GetOrgConnectByOrganizationIdResponse = {
  organization: OrganizationDto;
  medOrganizations: MedOrganizationDto[];
};
export type PatientModel = {
  id: number;
  personId: number;
  driveLicense?: string | null;
  organizationId?: number | null;
  isActive: boolean;
};
export type ChangePatientRequest = {
  driveLicense: string | null;
  organizationId: number | null;
};
export type ChangePersonPhotoRequest = {
  fileName: string;
};
export type PersonModel = {
  id: number;
  userId: number;
  lastName: string;
  firstName: string;
  patronymic?: string | null;
  birthDate: string;
  gender: GenderTypeEnum;
  email: string;
  phone: string;
  personalNumber?: string | null;
  position: string;
  notificationToken?: string | null;
  personPhotos?: PersonPhotoDto[] | null;
};
export type PersonModelPagedListModel = {
  page?: number | null;
  offset?: number | null;
  totalPages?: number | null;
  totalResults?: number | null;
  models?: PersonModel[] | null;
};
export type PersonModelPagedListModelRead = {
  page?: number | null;
  offset?: number | null;
  totalPages?: number | null;
  totalResults?: number | null;
  models?: PersonModel[] | null;
  hasNextPage?: boolean | null;
  hasPreviousPage?: boolean | null;
};
export type AddPersonRequest = {
  userId: number;
  lastName: string;
  firstName: string;
  patronymic?: string | null;
  birthDate: string;
  gender: GenderTypeEnum;
  email: string;
  phone: string;
  personalNumber?: string | null;
  position: string;
};
export type ChangePersonRequest = {
  userId: number;
  lastName: string;
  firstName: string;
  patronymic?: string | null;
  birthDate: string;
  gender: GenderTypeEnum;
  email: string;
  phone: string;
  personalNumber?: string | null;
  position: string;
};
export type JournalModel = {
  id: number;
  examId?: number | null;
  reportDate: string;
  driverLastName: string;
  driverFirstName: string;
  driverPatronymic?: string | null;
  birthDate: string;
  gender: GenderTypeEnum;
  hasComplaints: boolean;
  complaints?: string | null;
  systPres: number;
  diastPres: number;
  pulse: number;
  temperature: number;
  alco: number;
  isAdmitted: boolean;
  reasonText?: string | null;
  doctorId: number;
  doctorLastName: string;
  doctorFirstName: string;
  doctorPatronymic?: string | null;
  doctorThumbprint: string;
  hashSign?: string | null;
  signDate: string;
};
export type FileExtensionsEnum = 0 | 1;
export type SignJournalByIdModel = {
  id: number;
  hashSign: string;
};
export type SignJournalByExamIdModel = {
  examId: number;
  hashSign: string;
};
export type HashModel = {
  hash?: string | null;
};
export type AddProcedureDeviceRequest = {
  name: string;
  type: ProcedureDeviceTypeEnum;
  isManualStart: boolean;
  isSoundOn: boolean;
  timeout: number;
};
export type QuestionModel = {
  id: number;
  number: string;
  value: string;
  hasAdditional: boolean;
};
export type QuizModel = {
  id: number;
  examId: number;
  quizType: QuizTypeEnum;
  procDate: string;
};
export type AddQuizRequest = {
  examId: number;
  quizType: QuizTypeEnum;
};
export type ReasonModel = {
  id: number;
  value: string;
  hasAdditional: boolean;
};
export type ReferenceIndicatorDto = {
  id: number;
  patientId: number;
  systLower: number;
  systUpper: number;
  diastLower: number;
  diastUpper: number;
  pulseLower: number;
  pulseUpper: number;
};
export type AddReferenceIndicatorRequest = {
  patientId: number;
  systLower: number;
  systUpper: number;
  diastLower: number;
  diastUpper: number;
  pulseLower: number;
  pulseUpper: number;
};
export type RoleModel = {
  id: number;
  name: string;
};
export type MoType = {
  id: number;
  name: string;
  medicalWorkType: MedicalWorkTypeEnum;
};
export type MedOrganization = {
  id: number;
  name: string;
  inn?: string | null;
  ogrn?: string | null;
  isIp: boolean;
  contactFIO: string;
  contactPost?: string | null;
  contactPhone?: string | null;
  address: string;
  phone: string;
  email?: string | null;
  medLicence: string;
  recReestrMO?: string | null;
  numReestrMO?: string | null;
  moTypeId?: number | null;
  moType?: MoType;
};
export type AppUser = {
  id?: number;
  userName?: string | null;
  normalizedUserName?: string | null;
  email?: string | null;
  normalizedEmail?: string | null;
  emailConfirmed?: boolean;
  securityStamp?: string | null;
  concurrencyStamp?: string | null;
  phoneNumber?: string | null;
  phoneNumberConfirmed?: boolean;
  twoFactorEnabled?: boolean;
  lockoutEnd?: string | null;
  lockoutEnabled?: boolean;
  accessFailedCount?: number;
  status: UserStatusEnum;
  passwordHash: string;
};
export type Person = {
  id: number;
  lastName: string;
  firstName: string;
  patronymic?: string | null;
  birthDate: string;
  gender: GenderTypeEnum;
  email: string;
  phone: string;
  personalNumber?: string | null;
  position: string;
  notificationToken?: string | null;
  userId: number;
  user?: AppUser;
  personPhotos?: PersonPhoto[] | null;
};
export type Doctor = {
  id: number;
  qualificationType?: QualificationTypeEnum;
  scientificDegreeType?: ScientificDegreeTypeEnum;
  workExperience?: number | null;
  isActive?: boolean;
  medOrganizationId?: number | null;
  medOrganization?: MedOrganization;
  personId: number;
  person?: Person;
};
export type Organization = {
  id: number;
  name: string;
  inn?: string | null;
  ogrn?: string | null;
  isIp: boolean;
  contactFIO: string;
  contactPost?: string | null;
  contactPhone?: string | null;
  address: string;
  phone: string;
  email?: string | null;
  isPersonPhotoRequired: boolean;
};
export type ScheduleModel = {
  id: number;
  doctorId: number;
  doctor?: Doctor;
  organizationId?: number | null;
  organization?: Organization;
  startDate: string;
  endDate: string;
  peopleCount?: number | null;
};
export type SpecialityModel = {
  id: number;
  name: string;
};
export type SpeechTestProcModel = {
  id: number;
  examId: number;
  procDate: string;
  audio: string;
};
export type AddSpeechTestProcRequest = {
  examId: number;
  audio: string;
};
export type TemperatureProcModel = {
  id: number;
  examId: number;
  isSuccess: boolean;
  reason?: string | null;
  procDate: string;
  temperature: number;
  tempUnitType: TempUnitTypeEnum;
};
export type StartTempProcRequest = {
  examId: number;
  tempUnitType: TempUnitTypeEnum;
};
export type TerminalModelPagedListModel = {
  page?: number | null;
  offset?: number | null;
  totalPages?: number | null;
  totalResults?: number | null;
  models?: TerminalModel[] | null;
};
export type TerminalModelPagedListModelRead = {
  page?: number | null;
  offset?: number | null;
  totalPages?: number | null;
  totalResults?: number | null;
  models?: TerminalModel[] | null;
  hasNextPage?: boolean | null;
  hasPreviousPage?: boolean | null;
};
export type AddTerminalRequest = {
  serialNumber: string;
  status: TerminalStatusEnum;
  isMobile: boolean;
  hasHints: boolean;
  quizType: QuizTypeEnum;
  hasSpeechTest: boolean;
  hasMindTest: boolean;
  terminalLocation?: string | null;
  address: string;
  timezone: number;
  alcoJoinTemp: boolean;
  sendVideoEnabled: boolean;
  alcotesterDeviceId?: number | null;
  thermometorDeviceId?: number | null;
  tonometerDeviceId?: number | null;
};
export type ChangeTerminalRequest = {
  serialNumber: string;
  status: TerminalStatusEnum;
  isMobile: boolean;
  hasHints: boolean;
  quizType: QuizTypeEnum;
  hasSpeechTest: boolean;
  hasMindTest: boolean;
  terminalLocation?: string | null;
  address: string;
  timezone: number;
  alcoJoinTemp: boolean;
  sendVideoEnabled: boolean;
};
export type ChangeTerminalDevicesRequest = {
  tonometerName: string;
  alcotesterName: string;
  thermometorName: string;
};
export type TermoGigrModel = {
  id: number;
  terminalId: number;
  scanDate: string;
  tempearture: number;
  moisture: number;
};
export type AppRole = {
  id?: number;
  name?: string | null;
  normalizedName?: string | null;
  concurrencyStamp?: string | null;
};
export type UserRoleModel = {
  userId?: number;
  user?: AppUser;
  roleId?: number;
  role?: AppRole;
};
export type AddUserRoleRequest = {
  userId: number;
  roleId: number;
};
export type UserModel = {
  id: number;
  userName: string;
  status: UserStatusEnum;
};
export type UserModelPagedListModel = {
  page?: number | null;
  offset?: number | null;
  totalPages?: number | null;
  totalResults?: number | null;
  models?: UserModel[] | null;
};
export type UserModelPagedListModelRead = {
  page?: number | null;
  offset?: number | null;
  totalPages?: number | null;
  totalResults?: number | null;
  models?: UserModel[] | null;
  hasNextPage?: boolean | null;
  hasPreviousPage?: boolean | null;
};
export type AddUserRequest = {
  userName: string;
  password: string;
};
export type ChangeUserRequest = {
  userName: string;
  status: UserStatusEnum;
};
export type ChangePasswordRequest = {
  userName: string;
  oldPassword: string;
  newPassword: string;
};
export type ResetPasswordRequest = {
  userName: string;
  newPassword: string;
};
export type WorkPlaceModel = {
  id: number;
  doctorId: number;
  hospitalName: string;
  phone?: string | null;
  addressId: number;
  position?: string | null;
  startDate: string;
  endDate: string;
};
export const {
  useGetApiAlcoTestProcsQuery,
  useLazyGetApiAlcoTestProcsQuery,
  usePostApiAlcoTestProcsMutation,
  useGetApiAlcoTestProcsByIdQuery,
  useLazyGetApiAlcoTestProcsByIdQuery,
  usePutApiAlcoTestProcsByIdMutation,
  useDeleteApiAlcoTestProcsByIdMutation,
  useGetApiAlcoTestProcsGetByExamIdByExamIdQuery,
  useLazyGetApiAlcoTestProcsGetByExamIdByExamIdQuery,
  usePostApiAlcoTestProcsStartAlcoProcMutation,
  usePostApiAlcoTestProcsStartAlcoAndTempMutation,
  useGetApiAnswersQuery,
  useLazyGetApiAnswersQuery,
  usePostApiAnswersMutation,
  useGetApiAnswersByIdQuery,
  useLazyGetApiAnswersByIdQuery,
  usePutApiAnswersByIdMutation,
  useDeleteApiAnswersByIdMutation,
  usePostApiAnswersAddAnswersListMutation,
  useGetApiApiKeysQuery,
  useLazyGetApiApiKeysQuery,
  usePostApiApiKeysMutation,
  useGetApiApiKeysGetByTerminalIdByTerminalIdQuery,
  useLazyGetApiApiKeysGetByTerminalIdByTerminalIdQuery,
  usePostApiAuthenticationLoginMutation,
  usePostApiAuthenticationRefreshTokenMutation,
  useGetApiBloodPressProcsQuery,
  useLazyGetApiBloodPressProcsQuery,
  usePostApiBloodPressProcsMutation,
  useGetApiBloodPressProcsByIdQuery,
  useLazyGetApiBloodPressProcsByIdQuery,
  usePutApiBloodPressProcsByIdMutation,
  useDeleteApiBloodPressProcsByIdMutation,
  useGetApiBloodPressProcsGetByExamIdByExamIdQuery,
  useLazyGetApiBloodPressProcsGetByExamIdByExamIdQuery,
  usePostApiBloodPressProcsStartBloodProcMutation,
  useGetApiCertificatesQuery,
  useLazyGetApiCertificatesQuery,
  usePostApiCertificatesMutation,
  useGetApiCertificatesByIdQuery,
  useLazyGetApiCertificatesByIdQuery,
  usePutApiCertificatesByIdMutation,
  useDeleteApiCertificatesByIdMutation,
  useGetApiCertificatesGetAllByPersonIdByPersonIdQuery,
  useLazyGetApiCertificatesGetAllByPersonIdByPersonIdQuery,
  useGetApiConclusionsQuery,
  useLazyGetApiConclusionsQuery,
  usePostApiConclusionsMutation,
  useGetApiConclusionsByIdQuery,
  useLazyGetApiConclusionsByIdQuery,
  usePutApiConclusionsByIdMutation,
  useDeleteApiConclusionsByIdMutation,
  useGetApiConclusionsGetByExamIdByExamIdQuery,
  useLazyGetApiConclusionsGetByExamIdByExamIdQuery,
  useGetApiConclusionsGetAllByPatientIdByPatientIdQuery,
  useLazyGetApiConclusionsGetAllByPatientIdByPatientIdQuery,
  usePostApiConclusionsGenerateDocumentByConclusionIdMutation,
  usePostApiConclusionsSignByConclusionIdMutation,
  useGetApiDoctorsQuery,
  useLazyGetApiDoctorsQuery,
  usePostApiDoctorsMutation,
  useGetApiDoctorsByIdQuery,
  useLazyGetApiDoctorsByIdQuery,
  usePutApiDoctorsByIdMutation,
  useDeleteApiDoctorsByIdMutation,
  useGetApiDoctorsGetByPersonIdByPersonIdQuery,
  useLazyGetApiDoctorsGetByPersonIdByPersonIdQuery,
  useGetApiEducationsQuery,
  useLazyGetApiEducationsQuery,
  usePostApiEducationsMutation,
  useGetApiEducationsByIdQuery,
  useLazyGetApiEducationsByIdQuery,
  usePutApiEducationsByIdMutation,
  useDeleteApiEducationsByIdMutation,
  useGetApiExamsQuery,
  useLazyGetApiExamsQuery,
  usePostApiExamsMutation,
  useGetApiExamsByIdQuery,
  useLazyGetApiExamsByIdQuery,
  usePutApiExamsByIdMutation,
  useDeleteApiExamsByIdMutation,
  useGetApiExamsGetAllByPatientIdByPatientIdQuery,
  useLazyGetApiExamsGetAllByPatientIdByPatientIdQuery,
  useGetApiExamsCheckStartExamAvailableByPatientIdQuery,
  useLazyGetApiExamsCheckStartExamAvailableByPatientIdQuery,
  usePostApiExamsStartExamMutation,
  usePostApiExamsStopExamByIdMutation,
  usePutApiExamsChangeExamStatusByIdMutation,
  useGetApiFilesDownloadFileByFileNameQuery,
  useLazyGetApiFilesDownloadFileByFileNameQuery,
  usePostApiFilesUploadFileMutation,
  useGetApiFilesStaticDocsByTypeQuery,
  useLazyGetApiFilesStaticDocsByTypeQuery,
  usePostApiFillDbCleanAndFillDbMutation,
  usePostApiFillDbCleanDbMutation,
  usePostApiFillDbCreateProdUserMutation,
  useGetApiIPsQuery,
  useLazyGetApiIPsQuery,
  usePostApiIPsMutation,
  useGetApiIPsByIdQuery,
  useLazyGetApiIPsByIdQuery,
  usePutApiIPsByIdMutation,
  useDeleteApiIPsByIdMutation,
  useGetApiLuxmeterQuery,
  useLazyGetApiLuxmeterQuery,
  usePostApiLuxmeterMutation,
  useGetApiLuxmeterGetClosestByTerminalIdQuery,
  useLazyGetApiLuxmeterGetClosestByTerminalIdQuery,
  useGetApiMediasQuery,
  useLazyGetApiMediasQuery,
  usePostApiMediasMutation,
  useGetApiMediasByIdQuery,
  useLazyGetApiMediasByIdQuery,
  usePutApiMediasByIdMutation,
  useDeleteApiMediasByIdMutation,
  useGetApiMedOrganizationsQuery,
  useLazyGetApiMedOrganizationsQuery,
  usePostApiMedOrganizationsMutation,
  useGetApiMedOrganizationsByIdQuery,
  useLazyGetApiMedOrganizationsByIdQuery,
  usePutApiMedOrganizationsByIdMutation,
  useDeleteApiMedOrganizationsByIdMutation,
  useGetApiMindTestProcsQuery,
  useLazyGetApiMindTestProcsQuery,
  usePostApiMindTestProcsMutation,
  useGetApiMindTestProcsByIdQuery,
  useLazyGetApiMindTestProcsByIdQuery,
  usePutApiMindTestProcsByIdMutation,
  useDeleteApiMindTestProcsByIdMutation,
  useGetApiMoTypesQuery,
  useLazyGetApiMoTypesQuery,
  usePostApiMoTypesMutation,
  useGetApiMoTypesByIdQuery,
  useLazyGetApiMoTypesByIdQuery,
  usePutApiMoTypesByIdMutation,
  useDeleteApiMoTypesByIdMutation,
  useGetApiNotificationsQuery,
  useLazyGetApiNotificationsQuery,
  usePutApiNotificationsReadByIdMutation,
  usePutApiNotificationsReadAllByPersonIdMutation,
  useDeleteApiNotificationsByIdMutation,
  useDeleteApiNotificationsDeleteAllByPersonIdMutation,
  usePostApiNotificationsSendTestNotificationMutation,
  useGetApiOrganizationsQuery,
  useLazyGetApiOrganizationsQuery,
  usePostApiOrganizationsMutation,
  useGetApiOrganizationsByIdQuery,
  useLazyGetApiOrganizationsByIdQuery,
  usePutApiOrganizationsByIdMutation,
  useDeleteApiOrganizationsByIdMutation,
  useGetApiOrgConnectsQuery,
  useLazyGetApiOrgConnectsQuery,
  usePostApiOrgConnectsMutation,
  useDeleteApiOrgConnectsMutation,
  useGetApiOrgConnectsGetByOrganizationIdByOrganizationIdQuery,
  useLazyGetApiOrgConnectsGetByOrganizationIdByOrganizationIdQuery,
  useGetApiPatientsQuery,
  useLazyGetApiPatientsQuery,
  usePostApiPatientsMutation,
  useGetApiPatientsByIdQuery,
  useLazyGetApiPatientsByIdQuery,
  usePutApiPatientsByIdMutation,
  useDeleteApiPatientsByIdMutation,
  useGetApiPatientsGetByPersonalNumberByPersonalNumberQuery,
  useLazyGetApiPatientsGetByPersonalNumberByPersonalNumberQuery,
  useGetApiPatientsGetByPersonIdByPersonIdQuery,
  useLazyGetApiPatientsGetByPersonIdByPersonIdQuery,
  usePutApiPatientsChangePatientActivityByIdMutation,
  useGetApiPersonPhotosQuery,
  useLazyGetApiPersonPhotosQuery,
  useGetApiPersonPhotosByIdQuery,
  useLazyGetApiPersonPhotosByIdQuery,
  usePutApiPersonPhotosByIdMutation,
  useDeleteApiPersonPhotosByIdMutation,
  useGetApiPersonPhotosGetByPersonIdByPersonIdQuery,
  useLazyGetApiPersonPhotosGetByPersonIdByPersonIdQuery,
  usePutApiPersonPhotosApproveRequestMutation,
  usePutApiPersonPhotosDeclineRequestMutation,
  usePostApiPersonPhotosUploadMutation,
  useGetApiPersonsQuery,
  useLazyGetApiPersonsQuery,
  usePostApiPersonsMutation,
  useGetApiPersonsByIdQuery,
  useLazyGetApiPersonsByIdQuery,
  usePutApiPersonsByIdMutation,
  useDeleteApiPersonsByIdMutation,
  useGetApiPersonsCheckPersonalNumberUniqueByPersonalNumberQuery,
  useLazyGetApiPersonsCheckPersonalNumberUniqueByPersonalNumberQuery,
  usePutApiPersonsChangeNotificationTokenMutation,
  useGetApiPostTripJournalsQuery,
  useLazyGetApiPostTripJournalsQuery,
  useGetApiPostTripJournalsByIdQuery,
  useLazyGetApiPostTripJournalsByIdQuery,
  useDeleteApiPostTripJournalsByIdMutation,
  useGetApiPostTripJournalsGenerateJournalDocumentQuery,
  useLazyGetApiPostTripJournalsGenerateJournalDocumentQuery,
  usePostApiPostTripJournalsSignByIdMutation,
  usePostApiPostTripJournalsSignByExamIdMutation,
  useGetApiPostTripJournalsGetHashByExamIdByExamIdQuery,
  useLazyGetApiPostTripJournalsGetHashByExamIdByExamIdQuery,
  useGetApiPostTripJournalsGetHashByIdByIdQuery,
  useLazyGetApiPostTripJournalsGetHashByIdByIdQuery,
  useGetApiPreTripJournalsQuery,
  useLazyGetApiPreTripJournalsQuery,
  useGetApiPreTripJournalsByIdQuery,
  useLazyGetApiPreTripJournalsByIdQuery,
  useDeleteApiPreTripJournalsByIdMutation,
  useGetApiPreTripJournalsGenerateJournalDocumentQuery,
  useLazyGetApiPreTripJournalsGenerateJournalDocumentQuery,
  usePostApiPreTripJournalsSignByIdMutation,
  usePostApiPreTripJournalsSignByExamIdMutation,
  useGetApiPreTripJournalsGetHashByExamIdByExamIdQuery,
  useLazyGetApiPreTripJournalsGetHashByExamIdByExamIdQuery,
  useGetApiPreTripJournalsGetHashByIdByIdQuery,
  useLazyGetApiPreTripJournalsGetHashByIdByIdQuery,
  useGetApiProcedureDevicesQuery,
  useLazyGetApiProcedureDevicesQuery,
  usePostApiProcedureDevicesMutation,
  useGetApiProcedureDevicesByIdQuery,
  useLazyGetApiProcedureDevicesByIdQuery,
  usePutApiProcedureDevicesByIdMutation,
  useDeleteApiProcedureDevicesByIdMutation,
  useGetApiProcedureDevicesGetByNameByNameQuery,
  useLazyGetApiProcedureDevicesGetByNameByNameQuery,
  useGetApiQuestionsQuery,
  useLazyGetApiQuestionsQuery,
  usePostApiQuestionsMutation,
  useGetApiQuestionsByIdQuery,
  useLazyGetApiQuestionsByIdQuery,
  usePutApiQuestionsByIdMutation,
  useDeleteApiQuestionsByIdMutation,
  useGetApiQuizesQuery,
  useLazyGetApiQuizesQuery,
  usePostApiQuizesMutation,
  useGetApiQuizesByIdQuery,
  useLazyGetApiQuizesByIdQuery,
  usePutApiQuizesByIdMutation,
  useDeleteApiQuizesByIdMutation,
  useGetApiReasonsQuery,
  useLazyGetApiReasonsQuery,
  usePostApiReasonsMutation,
  useGetApiReasonsByIdQuery,
  useLazyGetApiReasonsByIdQuery,
  usePutApiReasonsByIdMutation,
  useDeleteApiReasonsByIdMutation,
  useGetApiReferenceIndicatorsQuery,
  useLazyGetApiReferenceIndicatorsQuery,
  usePostApiReferenceIndicatorsMutation,
  useGetApiReferenceIndicatorsByIdQuery,
  useLazyGetApiReferenceIndicatorsByIdQuery,
  usePutApiReferenceIndicatorsByIdMutation,
  useDeleteApiReferenceIndicatorsByIdMutation,
  useGetApiReferenceIndicatorsGetByPatientIdByPatientIdQuery,
  useLazyGetApiReferenceIndicatorsGetByPatientIdByPatientIdQuery,
  useGetApiReportsGeneratePivotTableQuery,
  useLazyGetApiReportsGeneratePivotTableQuery,
  useGetApiReportsGenerateExamReportQuery,
  useLazyGetApiReportsGenerateExamReportQuery,
  useGetApiRolesQuery,
  useLazyGetApiRolesQuery,
  usePostApiRolesMutation,
  useGetApiRolesByIdQuery,
  useLazyGetApiRolesByIdQuery,
  usePutApiRolesByIdMutation,
  useDeleteApiRolesByIdMutation,
  useGetApiRolesGetByNameByNameQuery,
  useLazyGetApiRolesGetByNameByNameQuery,
  useGetApiSchedulesQuery,
  useLazyGetApiSchedulesQuery,
  usePostApiSchedulesMutation,
  useGetApiSchedulesByIdQuery,
  useLazyGetApiSchedulesByIdQuery,
  usePutApiSchedulesByIdMutation,
  useDeleteApiSchedulesByIdMutation,
  useGetApiSpecialitiesQuery,
  useLazyGetApiSpecialitiesQuery,
  useGetApiSpecialitiesByIdQuery,
  useLazyGetApiSpecialitiesByIdQuery,
  useGetApiSpeechTestProcsQuery,
  useLazyGetApiSpeechTestProcsQuery,
  usePostApiSpeechTestProcsMutation,
  useGetApiSpeechTestProcsByIdQuery,
  useLazyGetApiSpeechTestProcsByIdQuery,
  usePutApiSpeechTestProcsByIdMutation,
  useDeleteApiSpeechTestProcsByIdMutation,
  useGetApiTemperatureProcsQuery,
  useLazyGetApiTemperatureProcsQuery,
  usePostApiTemperatureProcsMutation,
  useGetApiTemperatureProcsByIdQuery,
  useLazyGetApiTemperatureProcsByIdQuery,
  usePutApiTemperatureProcsByIdMutation,
  useDeleteApiTemperatureProcsByIdMutation,
  useGetApiTemperatureProcsGetByExamIdAsyncByExamIdQuery,
  useLazyGetApiTemperatureProcsGetByExamIdAsyncByExamIdQuery,
  usePostApiTemperatureProcsStartTempProcMutation,
  usePostApiTemperatureProcsStartAlcoAndTempMutation,
  useGetApiTerminalsQuery,
  useLazyGetApiTerminalsQuery,
  usePostApiTerminalsMutation,
  useGetApiTerminalsByIdQuery,
  useLazyGetApiTerminalsByIdQuery,
  usePutApiTerminalsByIdMutation,
  useDeleteApiTerminalsByIdMutation,
  useGetApiTerminalsGetBySerialNumberBySerialNumberQuery,
  useLazyGetApiTerminalsGetBySerialNumberBySerialNumberQuery,
  usePostApiTerminalsByIdRebootMutation,
  usePutApiTerminalsChangeTerminalStatusMutation,
  usePutApiTerminalsByIdChangeDevicesMutation,
  useGetApiTermoGigrsQuery,
  useLazyGetApiTermoGigrsQuery,
  usePostApiTermoGigrsMutation,
  useGetApiTermoGigrsGetAllByTerminalIdTerminalIdQuery,
  useLazyGetApiTermoGigrsGetAllByTerminalIdTerminalIdQuery,
  useGetApiTermoGigrsGetClosestByTerminalIdQuery,
  useLazyGetApiTermoGigrsGetClosestByTerminalIdQuery,
  useGetApiUserRolesQuery,
  useLazyGetApiUserRolesQuery,
  usePostApiUserRolesMutation,
  useGetApiUserRolesGetAllByUserIdByUserIdQuery,
  useLazyGetApiUserRolesGetAllByUserIdByUserIdQuery,
  useGetApiUserRolesGetAllByRoleIdByRoleIdQuery,
  useLazyGetApiUserRolesGetAllByRoleIdByRoleIdQuery,
  useGetApiUserRolesUserIdRoleIdQuery,
  useLazyGetApiUserRolesUserIdRoleIdQuery,
  useDeleteApiUserRolesUserIdRoleIdMutation,
  useGetApiUsersQuery,
  useLazyGetApiUsersQuery,
  usePostApiUsersMutation,
  useGetApiUsersByIdQuery,
  useLazyGetApiUsersByIdQuery,
  usePutApiUsersByIdMutation,
  useDeleteApiUsersByIdMutation,
  useGetApiUsersCheckUserNameUniqueByUserNameQuery,
  useLazyGetApiUsersCheckUserNameUniqueByUserNameQuery,
  usePutApiUsersChangeUserStatusMutation,
  usePutApiUsersChangePasswordMutation,
  usePutApiUsersResetPasswordMutation,
  useGetApiWorkPlacesQuery,
  useLazyGetApiWorkPlacesQuery,
  usePostApiWorkPlacesMutation,
  useGetApiWorkPlacesByIdQuery,
  useLazyGetApiWorkPlacesByIdQuery,
  usePutApiWorkPlacesByIdMutation,
  useDeleteApiWorkPlacesByIdMutation,
} = injectedRtkApi;
