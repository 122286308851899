import { Tooltip } from "antd";
import { Doctor } from "./apiRTK";

import { EnumRoles } from "./types";

export function checkIfNeedCert(roleName: string) {
  if (
    roleName === EnumRoles.Руководитель ||
    roleName === EnumRoles.Врач ||
    roleName === EnumRoles.Механик
  ) {
    return true;
  } else {
    return false;
  }
}

export function checkIfCanSign(
  role: EnumRoles | null,
  examStatus: number | undefined,
  doctor: Doctor | undefined
) {
  if (role === "Врач" && examStatus === 4 && doctor) {
    return true;
  } else {
    return false;
  }
}

export function countTruthyParams(...params: any): number {
  return params.filter((param: any) => !!param === true).length;
}

export function getFioWithDots(
  lastName: string,
  firstName: string,
  patronymic: string,
  maxSymbols: number
) {
  const fio = `${lastName} ${firstName} ${patronymic}`;
  if (fio.length > maxSymbols) {
    return (
      <Tooltip title={`${lastName} ${firstName} ${patronymic}`}>
        {fio.substring(0, maxSymbols - 2) + "..."}
      </Tooltip>
    );
  } else {
    return fio;
  }
}

export const normalizePhone = (phone: string | undefined) => {
  if (phone) {
    const newPhone = phone.replace("+", "");

    if (typeof +newPhone === "number" && newPhone.length === 11) {
      const result = `+${7} (${newPhone[1]}${newPhone[2]}${newPhone[3]}) ${
        newPhone[4]
      }${newPhone[5]}${newPhone[6]}-${newPhone[7]}${newPhone[8]}-${
        newPhone[9]
      }${newPhone[10]}`;

      return result;
    }
  }

  return phone;
};

export const unnormalizePhone = (maskedPhone: string) => {
  return maskedPhone.replace(/\D/g, "");
};

export const getExamTypeById = (id: number) => {
  if (id === 0) {
    return "Предрейсовый";
  } else if (id === 1) {
    return "Предсменный";
  } else if (id === 2) {
    return "Послерейсовый";
  }

  return "Послесменный";
};

export function getRealFilterData<T extends object>(
  filterString: string,
  sample: T
) {
  return filterString.split(" ").filter((filterString) => {
    return filterString in sample;
  });
}

export function checkIfOutOfRange(
  checkData: number,
  typeData:
    | "syst"
    | "diast"
    | "pulse"
    | "luminosity"
    | "temperature"
    | "moisture",
  rangeData: { [key: string]: number } | undefined
) {
  if (!rangeData) {
    return false;
  }

  if (typeData === "syst") {
    if (
      checkData > rangeData["systUpper"] ||
      checkData < rangeData["systLower"]
    ) {
      return true;
    }
  }

  if (typeData === "diast") {
    if (
      checkData > rangeData["diastUpper"] ||
      checkData < rangeData["diastLower"]
    ) {
      return true;
    }
  }

  if (typeData === "pulse") {
    if (
      checkData > rangeData["pulseUpper"] ||
      checkData < rangeData["pulseLower"]
    ) {
      return true;
    }
  }

  if (typeData === "luminosity") {
    if (
      checkData > rangeData["luminosityUpper"] ||
      checkData < rangeData["luminosityLower"]
    ) {
      return true;
    }
  }

  if (typeData === "temperature") {
    if (
      checkData > rangeData["temperatureUpper"] ||
      checkData < rangeData["temperatureLower"]
    ) {
      return true;
    }
  }

  if (typeData === "moisture") {
    if (
      checkData > rangeData["moistureUpper"] ||
      checkData < rangeData["moistureLower"]
    ) {
      return true;
    }
  }

  return false;
}

// Функция для сохранения файла на компьютер,
// можно передать либо ссылку на файл либо сам файл в формате Blob
export const downloadFileAnchor = async (
  data: string | Blob,
  filename: string
) => {
  const anchor = document.createElement("a");
  let myBlob = undefined;

  if (typeof data === "string") {
    const fetchedBlob = (await fetch(data).then((response) =>
      response.blob()
    )) as Blob;
    myBlob = URL.createObjectURL(fetchedBlob);
  } else {
    myBlob = URL.createObjectURL(data);
  }

  anchor.href = myBlob;
  anchor.download = filename;

  document.body.appendChild(anchor);

  anchor.click();
  anchor.remove();
};
